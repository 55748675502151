
<!doctype html>

<html lang="es">
<head>


  <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>

  <meta name="robots" content="index, follow" />
  <meta name="keywords" content="" />
  <meta name="description" content="" />

  <title></title>

  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <meta http-equiv="expires" content="wed, 30 jul 2014 14:30:00 GMT">

  <meta property="og:site_name" content=""/>
  <meta property="og:title" content=""/>
  <meta property="og:description" content=""/>
  <meta property="og:url" content="" />
  <meta property="og:image" content=""/>

  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap" rel="stylesheet">



  <!--[if lt IE 9]>
  	<script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
  	<script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
  <![endif]-->

</head>
<body>

  <header class="bg-header wow animate fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
    <h1>
      Logo Ricón Árabe
      <a class="bg-header" [routerLink]="['/']">
        <img src="/assets/img/logo.png" alt="">
      </a>
    </h1>

    <ng-container *ngIf="'Guest' != user.displayName; else guess_user">
      <nav class="bg-header">
        <ul>
          <li><a [routerLink]="['/history']">Mis pedidos</a></li>
          <li><a href="mailto:contacto@riconarabe.cl">Contacto</a></li>
          <li><a [routerLink]="['/stores']">Tiendas</a></li>
          <li><a [routerLink]="['/addresses']">Direcciones</a></li>
          <li><a (click)="logout()">Cerrar sesión</a></li>
        </ul>
      </nav>
    </ng-container>
    <ng-template #guess_user>
      <nav class="bg-header">
        <ul>
          <li><a href="mailto:contacto@riconarabe.cl">Contacto</a></li>
          <li><a [routerLink]="['/stores']">Tiendas</a></li>
        </ul>
      </nav>
    </ng-template>
    <a [routerLink]="['/cart']" class="car">
      <img src="/assets/img/car.png" alt="">
      <span>{{cartNumberProducts}}</span>
    </a>

    <a class="login" (click)="loginWithGoogle()">
      <img src="/assets/img/login.png" alt="">
    </a>

    <a class="login-perfil" (click)="loginWithGoogle()">
      <p>{{(textForCustomer(user))}}</p>
      <img src={{imageForCustomer(user)}} alt="">
    </a>

    <button class="burger">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </header>

  <ng-container *ngIf="!isOpen && showCloseBanner; else emtpyBanner">
    <div class="alert-bar"><p>{{closeStoreText()}}</p><a (click)="closeBanner()">x</a></div>
    </ng-container>
    <ng-template #emtpyBanner>
      <div ></div>
    </ng-template>


<main>

  <ng-container *ngIf="null!=promotion; else loader">

  <section class="main-banner dark wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
    <div class="owl-carousel">
      <div class="item">
        <h2 class="banner-tittle">{{promotion.name}}</h2>
        <figure class="imgLiquid"><img src={{promotion.imgs[0]}} alt=""></figure>
      </div>

    </div>
  </section>

  <section class="combo wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1.5s">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12">
          <h2 class="section-tittle">{{promotion.name}}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <figure class="imgLiquid"><img src={{promotion.imgs[0]}} alt=""></figure>
        </div>

      <div class="col-12 col-md-6">
        <div class="detail">



          <p>
            <span class="price">{{promotion.price_str}}</span>
            {{promotion.description}}
          </p>
          <div class="btn taste">
            <span>{{productAName ()}}</span>

            <ul class="tasteList">
              <li  *ngFor="let product of productsA">
                <a (click)="selectProductA(product)">
                  <p>{{product.name}}</p>
                </a>
              </li>

            </ul>
          </div>


          <div class="btn taste">
            <span>{{productBName ()}}</span>

            <ul class="tasteList">
              <li  *ngFor="let product of productsB">
                <a (click)="selectProductB(product)">
                  <p>{{product.name}}</p>
                </a>
              </li>

            </ul>
          </div>



          <ng-container *ngIf="selectedProductB; else hidedButton">


            <a class="btn" (click)="addToCart()">Añadir al carrito</a>
          </ng-container>
            <ng-template #hidedButton>
              <a></a>
              </ng-template>



        </div>

      </div>
    </div>

    </div>
  </section>

</ng-container>
<ng-template #empty>
  <div ></div>
</ng-template>
<ng-template #loader>
  <div class="loader">Loading...</div>
</ng-template>

</main>

<footer>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<a href="javascript:void(0)" class="top bg-header"><img src="assets/img/arrow-top.png" alt=""></a>
				<ul>
          <li><a href={{facebookUrl()}} target="_blank" rel="noopener noreferrer"><img src="/assets/img/ico-fb.png" alt=""></a></li>
          <!-- <li><a href="#"><img src="/assets/img/ico-tw.png" alt=""></a></li>
          <li><a href="#"><img src="/assets/img/ico-yt.png" alt=""></a></li> -->
          <li><a href={{instagramUrl()}} target="_blank" rel="noopener noreferrer"><img src="/assets/img/ico-insta.png" alt=""></a></li>

				</ul>
				<p>Dirección</p>
			</div>
		</div>
	</div>

</footer>


<script src="js/jquery.js"></script>
<script src="js/jquery-ui.js"></script>
<script src="js/modernizr.min.js"></script>
<script src="js/bootstrap.min.js"></script>
<script src="js/wow.min.js"></script>
<script src="js/jquery.parallax.js"></script>
<script src="js/owl.carousel.js"></script>
<script src="js/scripts.js"></script>
</body>
</html>
