import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { BaseComponent } from "../base.component";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { FirestoreService } from "../services/firestore.service";
import { MessagingService } from "../services/messaging.service";

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
  })
  
  export class CategoriesComponent extends BaseComponent {

    public category: any = null;
    public products: Array<any> = [];
    public discountProducts: Array<any> = [];

    constructor(
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef, 
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService,
      private route: ActivatedRoute) {
              
        super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);

        console.log(this.route);

        this.route.params.subscribe(async params => {

            this.uuid = params['uuid'];

            console.log(this.uuid);

            this.category = await this.dataService.categoryByUID(this.uuid);

            console.log("category:", this.category);
      
            this.products = await this.dataService.productsByCategory(this.category.products);

            const index = this.products.length > 5 ? 4 : (this.products.length)

            this.discountProducts = this.products.slice(0, index);

            this.discountProducts.sort((x,y) => {return x.price_discount - y.price_discount})

            console.log("discountProducts:", this.discountProducts);

        });
        
    }

  

  }
