import { Component, OnInit, ViewChild, ElementRef, Injectable } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgwWowService } from 'ngx-wow';

require('typeface-oswald');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  @ViewChild('navBurger') navBurger!: ElementRef;
  @ViewChild('navMenu') navMenu!: ElementRef;

  constructor(private wowService: NgwWowService) {

    this.wowService.init();

  }

  ngOnInit() {

  }

  sendToServer(params: any) {

    console.log("params", params);

  }

  toggleNavbar() {

    console.log("toggleNavbar");

    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');

  }

}
