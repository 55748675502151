import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { BaseComponent } from "../base.component";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { FirestoreService } from "../services/firestore.service";
import { MessagingService } from "../services/messaging.service";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";

@Component({
    selector: 'app-stores',
    templateUrl: './stores.component.html'
  })
  
  export class StoresComponent extends BaseComponent {

    constructor(
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef, 
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService,
      private route: ActivatedRoute) {
              
        super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);

      }


    async cartReady() {

      super.cartReady()
      this.promotions = await this.dataService.currentPromotions();

    }

  }

  
