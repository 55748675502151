
<!doctype html>

<html lang="es">
<head>


  <script src="js/jquery.js"></script>
  <script src="js/jquery-ui.js"></script>
  <script src="js/modernizr.min.js"></script>
  <script src="js/bootstrap.min.js"></script>
  <script src="js/wow.min.js"></script>
  <script src="js/jquery.parallax.js"></script>
  <script src="js/owl.carousel.js"></script>
  <script src="js/scripts.js"></script>

  <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>

  <meta name="robots" content="index, follow" />
  <meta name="keywords" content="" />
  <meta name="description" content="" />

  <title>Pascucci</title>

  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <meta http-equiv="expires" content="wed, 30 jul 2014 14:30:00 GMT">

  <meta property="og:site_name" content=""/>
  <meta property="og:title" content=""/>
  <meta property="og:description" content=""/>
  <meta property="og:url" content="" />
  <meta property="og:image" content=""/>

  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;700&display=swap" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap" rel="stylesheet">

  <!--[if lt IE 9]>
  	<script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
  	<script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
  <![endif]-->

</head>

<body>

  <notifier-container></notifier-container>

  <header class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay=".5s">
    <h1><a href="https://pascucci.cl/"></a></h1>

    <button class="burger">
      <img src="/assets/img/logo.png" alt="">
      <span></span>
      <span></span>
        <span></span>
    </button>

    <div class="cont-lupa">
      <input class="buscar" type="text" id="name" name="name" placeholder="Buscar" required />
    </div>

    <a [routerLink]="['/cart']" class="carrito" href="carrito-compras.html">
      <span class="contador">{{cartNumberProducts}}</span>
      <img src="images/carrito.svg" alt="">
    </a>

    <ng-container *ngIf="'Guest' != user.displayName; else guess_user">
      <a (click)="logout()" style="cursor: hand;cursor: pointer;" class="tienda">Cerrar sesión</a>
    </ng-container>
    <ng-template #guess_user>
      <a (click)="loginWithGoogle()" style="cursor: hand;cursor: pointer;" class="tienda">Inicia Sesión</a>
    </ng-template>

    <img class="bandera" src="images/bandera.png" alt="">
  </header>

  <nav class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay=".5s">
    <div class="box">
      <div *ngFor="let family of families" class="box-inner">
        <a [routerLink]="[family.link]">{{family.name}}</a>
      </div>
    </div>
  </nav>

  <main>


    <section class="carrito wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">

      <div  [ngSwitch]="true">

        <div class="container" *ngSwitchCase="initialLoading === true || loading === true">

           <div class="row">
            <div class="col-12">
              <div class="loader">Cargando...</div>
            </div>
          </div>

          <div class="col-7 col-md-9">
            <div class="detail">
              <p>


              </div>
            </div>

          <div class="row">
            <div class="col-12">
              <!-- <p class="main">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p> -->
            </div>
          </div>

        </div>



        <div class="container" *ngSwitchCase="orderedCompleted === true && initialLoading === false && loading === false ">


          <section class="carrito revision wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12">
                  <h2 class="section-title">Gracias por tu <strong>compra</strong></h2>
                </div>
                <div class="col-12 col-md-8">
                  <p class="text-center">
                    En minutos recibirás un correo con la confirmación de tu compra.<br><br>

                    Tu número de compra es <strong class="">{{commerceOrderDataOrderId}}</strong><br><br>

                    Posteriormente, recibirás el documento tributario correspondiente</p>
                </div>
              </div>
            </div>
          </section>


          <div></div>

       </div>





      <div class="container" *ngSwitchCase="initialLoading === false && loading === false && orderedCompleted === false">

        <div  [ngSwitch]="true">

        <div class="row">
          <div class="col-12">
            <h2 class="section-title">Carrito de <strong>Compras</strong></h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <div class="cont-carro">
              <ul class="header-carro">
                <li class="producto">
                  <span>Producto</span>
                </li>
                <li class="cantidad">
                  <span>Cantidad</span>
                </li>
                <li class="precio-unitario">
                  <span>Precio Unitario</span>
                </li>
                <li class="subtotal">
                  <span>Subtotal</span>
                </li>
                <li class="borrar"></li>
              </ul>


              <ul class="item" *ngFor="let product of products">
                <li class="producto">
                  <figure><img src={{product.imgs[0]}} alt=""></figure>
                  <span>{{product.name}}</span>
                </li>
                <li class="cantidad">
                  <div class="calculadora">
                    <div class="valor">
                      <span class="numero">{{cartData[product.uid]}}</span>
                    </div>
                    <div class="calc">
                      <a (click)="add(product.uid)" style="cursor: hand;cursor: pointer;">+</a>
                      <a (click)="substract(product.uid)" style="cursor: hand;cursor: pointer;">-</a>
                    </div>
                  </div>
                </li>
                <li class="precio-unitario">
                  <span>{{priceForProduct(product)}}</span>
                </li>
                <li class="subtotal">
                  <span>{{totalForProduct(product.uid)}}</span>
                </li>
                <li class="borrar">
                  <a (click)="remove(product.uid)" style="cursor: hand;cursor: pointer;"><img src="images/delete.svg" alt=""></a>
                </li>
              </ul>


              <a (click)="buy($event)" style="cursor: hand;cursor: pointer;"  class="ver-btn">Realizar pedido</a>
              <a (click)="backAction()" style="cursor: hand;cursor: pointer;" class="back-btn"><img src="images/arrow-back-black.svg" alt="">Continúa comprando</a>

            </div>
          </div>

          <div class="col-12 col-md-4">
            <div class="box-costo-envio">
              <!-- <h3>Costo estimado de envío</h3>
              <form action="">
                <label for="">País</label>
                <select name="" id="">
                  <option value="">Chile</option>
                  <option value="">Argentina</option>
                  <option value="">Perú</option>
                </select>

                <label for="">Región</label>
                <select name="" id="">
                  <option value="">Metropolitana</option>
                  <option value="">I</option>
                  <option value="">II</option>
                </select>

                <label for="">Comuna</label>
                <select name="" id="">
                  <option value="">La Cisterna</option>
                  <option value="">Providencia</option>
                  <option value="">Recoleta</option>
                </select>
              </form> -->

              <!-- <a href="#" class="ver-btn calcular">Calcular Envío</a> -->

              <div class="separador"></div>

              <div class="tipo-envio">
                <form action="">
                  <div class="retiro-tienda">
                    <div class="info-1">
                      <ng-container *ngIf="null != selectedStore; else noStore">
                        <p><input type="radio" checked> Retiro Tienda, {{selectedStore.address}}, Horario: 8 a 21hrs. Teléfono: {{selectedStore.phone}}</p>
                      </ng-container>
                        <ng-template #noStore>
                          <p><input type="radio" checked> Retiro Tienda, Horario: 8 a 21hrs.</p>
                        </ng-template>
                    </div>
                    <div class="info-2">
                      <span>$0</span>
                    </div>
                  </div>

                  <div class="disponible">
                    <!-- <h3>Disponible</h3> -->
                    <form action="">
                      <label for="">Ver disponibilidad en tiendas</label>
                      <select (change)="selectStore($event)">
                        <option name="selectedStore"  *ngFor="let store of stores; let i = index" [value]="store.name" >
                          {{store.address}}
                        </option>
                      </select>
                    </form>
                  </div>

                  <!-- <div class="no-disponible">
                    <h3>Sin stock</h3>
                  </div>

                  <div class="envio">
                    <div class="info-1">
                      <p><input type="radio"> Hasta 4 días hábiles (Sólo Provincia de Santiago - Región Metropolitana)</p>
                    </div>
                    <div class="info-2">
                      <span>$4.000</span>
                    </div>
                  </div> -->
                </form>

                <!-- <a href="#" class="ver-btn definir">Definir Envío</a>

                <div class="separador"></div> -->

              </div>

              <h3>Revisión del Pedido</h3>

              <div class="separador"></div>

              <h3>
                <span class="sub-total">Subtotal </span>
                <span class="sub-total-precio">{{total()}}</span>
              </h3>

              <div class="separador"></div>

              <h3>
                <span class="envio-total">Envío </span>
                <span class="envio-total-precio">$4.000</span>
              </h3>

              <div class="separador"></div>

              <h3>
                <span class="total">Total</span>
                <span class="total-precio">{{total()}}</span>
              </h3>

              <div class="separador"></div>

              <a (click)="buy($event)" class="ver-btn">Realizar pedido</a>
              <a (click)="backAction()" class="back-btn"><img src="images/arrow-back-black.svg" alt="">Continúa comprando</a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

    </section>

    <section class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 p-0">
            <div class="separador"></div>
          </div>
        </div>
      </div>

      <span id="nav" *ngIf="showLoading()">
        <div class="overlay">
          <!-- <div class="loader">Cargando...</div> -->

        </div>
      </span>


    </section>

</main>


<footer class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
  <div class="container">

    <div class="row">
      <div class="col-12 col-md-3">
        <img class="corazon" src="images/corazon.png" alt="">
      </div>

      <div class="col-12 col-md-3">
        <h2>Contacto Venta y Post venta</h2>
        <p>
          <a href="mailto:contacto@pascucci.cl">contacto@pascucci.cl</a><br>
          WhatsApp: +56 9 9 2303318<br><br>

          <a href="javascript:void(0)">Términos y condiciones</a><br>
          <a href="javascript:void(0)">Políticas de devolución y reembolso</a>
        </p>
      </div>

      <div class="col-12 col-md-3">
        <h2>Venta empresas y convenios</h2>
        <p>
          <a href="mailto:monica.concha@pascucci.cl">monica.concha@pascucci.cl</a><br>
          WhatsApp: +56 9 9 2303318<br><br>

          <a href="mailto:flor.ormino@pascucci.cl">flor.ormino@pascucci.cl</a><br>
          WhatsApp: +56 9 94438263
        </p>
      </div>

      <div class="col-12 col-md-3">
        <h2>Menú</h2>
        <p>
          <a href="https://www.pascucci.cl/nosotros.html" target="_blank">Nuestra historia</a><br>
          <a href="https://www.pascucci.cl/nosotros.html" target="_blank">Trazabilidad y comercio justo</a><br>
          <a href="https://www.pascucci.cl/locales.html" target="_blank">Nuestras tiendas</a><br>
          <a href="javascript:void(0)">Nuestra carta</a><br>
          App <a class="icos" href="#" target="_blank"><img src="images/apple.png" alt=""></a> <a class="icos" href="https://play.google.com/store/apps/details?id=cl.nhm.app.app.pascucci&hl=es_PY&pli=1" target="_blank"><img src="images/googleplay.png" alt=""></a>
          <a href="https://www.pascucci.cl/franquicia.html" target="_blank">Franquicias</a><br>
          <a href="https://www.pascucci.cl/trabaja.html" target="_blank">Trabaja con nosotros</a>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <h2>Síguenos</h2>
        <ul class="rrss">
          <li><a href="https://www.facebook.com/caffepascuccichile" target="_blank"><img src="images/icoface.webp" alt=""></a></li>
          <li><a href="https://twitter.com/pascucci_CL" target="_blank"><img src="images/icotwitter.webp" alt=""></a></li>
          <li><a href="https://www.youtube.com/user/pascucciespresso" target="_blank"><img src="images/icoyoutube.webp" alt=""></a></li>
          <li><a href="https://www.instagram.com/pascucci_cl/" target="_blank"><img src="images/ico_instagram.webp" alt=""></a></li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        <ul class="sellos">
          <li><img src="images/sello-1.png" alt=""></li>
          <li><img src="images/sello-2.png" alt=""></li>
          <li><img src="images/sello-3.png" alt=""></li>
          <li><img src="images/sello-4.png" alt=""></li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        <img class="tarjetas" src="images/tarjetas.png" alt="">
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="text-center">2024 Texto marca registrada Texto marca registrada Texto marca registrada Texto marca registrada Texto marca registrada</p>
      </div>
    </div>
    <div class="row arrow-bottom">
      <div class="col-12">
        <a href="javascript:void(0)" class="arrow-top">
          <span></span>
          <span></span>
        </a>
      </div>
    </div>
  </div>
</footer>



    </body>


    <script>
      function selectProduct(e, product) {
        console.log('x')

          // ...  your function code
          // e.preventDefault();   // use this to NOT go to href site
      }
  </script>


<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


