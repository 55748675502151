import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, HostListener } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import { OnInit } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { threadId } from "worker_threads";
import { AuthService } from "./services/auth.service";
import { DataService } from "./services/data.service";
import { FirestoreService } from "./services/firestore.service";
import swal from 'sweetalert2';
import { MessagingService } from "./services/messaging.service";
import { NotifierService } from "angular-notifier/lib/services/notifier.service";

declare const gapi: any;

@Component({
    selector: 'app-base',
    template: `
        <div>
            base works!!
        </div>
    `
})


export class BaseComponent implements OnInit {

    title = 'push-notification';

    [x: string]: any;

    public loading = false;
    public uuid!: string;
    public auth2: any;

    public user: any = { uid: null,
            displayName: "Guest",
                    img: "https://firebasestorage.googleapis.com/v0/b/app2020-cb4eb.appspot.com/o/login.png?alt=media&token=9f8c887a-d4dc-4481-9237-f54e3612792f",
                  email: "",
              addresses: []}

    public cartNumberProducts = 0;
    public cart: any = null;
    public store: any = null;
    public userPoints = 0;
    public status = false;

    private dataLoadedSubscription: Subscription | undefined;
    private cartSubscription: Subscription | undefined;
    private userSubscription: Subscription | undefined;

    public promotions: Array<any> = [];
    public products: Array<any> = [];

    isOpen = true
    showCloseBanner = true

    constructor(
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef,
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService
    ) {

    }

    async ngOnInit() {
      console.log('base user.displayName: ', this.user.displayName ?? '');

      this.dataLoadedSubscription?.unsubscribe();

      this.dataLoadedSubscription = this.dataService.dataLoaded.subscribe (async newStatus => {

       // console.log("status", this.status);
        //console.log("status newStatus", newStatus);

        if (this.status != newStatus) {

          this.status = newStatus

       //   console.log("update Status", newStatus);

          this.cartSubscription?.unsubscribe()
          this.userSubscription?.unsubscribe()

            this.cartSubscription = this.dataService.currentCart?.subscribe((data: any) => {

                this.updateCart(data);

                this.cartNumberProducts = undefined !== data.products ? data.products.length : 0

                this.cartReady()

            });

            this.userSubscription = this.dataService.currentUser?.subscribe((data: any) => {

              //console.log("user loadData", data);

                this.user = data

                this.loadData ()

                if (null == this.token && this.user.uid != null && this.dataService.fcmToken == null) {

                  this.messagingService.requestPermission()
                  this.messagingService.receiveMessage()
                  this.message = this.messagingService.currentMessage
                  this.token = this.messagingService.currentToken

                  this.token.subscribe (async (fcmTokenValue: string) => {

                    if (fcmTokenValue?.length > 0) {

                      //console.log('fcmToken', fcmTokenValue);

                      this.dataService.fcmToken = fcmTokenValue;

                      this.firestoreService.updateCustomer(this.user.uid ?? "", {fcmWebToken: fcmTokenValue})

                    }

                  })

                  this.message.subscribe (async (messageData: any) => {

                    if (null !== messageData.data && undefined !== messageData.data) {

                      //console.log('message received', messageData);

                      this.showNotification("",messageData.data.body);
                    }



                  })

                }

            });

        }

      })


      this.store = await this.dataService.currentStore();

   //   console.log("stores:", this.store);

      this.dataService.checkTime.subscribe (async time => {

        this.checkTime()

      })

      window.onbeforeunload = () => this.ngOnDestroy();

    }

    async loadData () {
      this.userPoints = this.user.userPoints || 0
    }

    loadScreenData (){

    }

    updateCart (newCart: any) {
      this.cart = newCart
    }

    checkTime () {

      const date = new Date()
      var day = date.getDay()
      day = day == 0 ? 7 : day
      const hour = date.getHours()
      const minute = date.getMinutes()

      if ((this.store.days || []).length > 0) {
        const dataDay = this.store.days[day-1]

        if (dataDay) {

          const start = 100 * (dataDay.start.hour || 0) + (dataDay.start.minute || 0)
          const end = 100 * (dataDay.end.hour || 0) + (dataDay.end.minute || 0)
          const current = 100 * (hour || 0) + (minute || 0)


          this.isOpen = dataDay.status && (current > start && current < end)

        }
      }else {
        this.isOpen = true
      }



    }

    public closeStoreText() {
      return "Ya cerramos"
    }

    closeBanner () {
      this.showCloseBanner = false
    }

    async cartReady() {
        console.log('cartReady parent');
    }

    loginWithGoogle () {

        console.log('loginWithGoogle');

        if (!this.authService.isLoggedIn()) {
           this.authService
            .loginWithGoogle()
            .then(res => {
                console.log('loginWithGoogle', res);

            })
            .catch(err =>
                console.log(err.message));
        }

    }

    logout () {

        if (this.authService.isLoggedIn()) {
            this.authService.logout()
        }

    }

  public showNotification (title: string, message: string) {

    this.notifierService.show({
      type: 'info',
      message: message,
      id: '' + Date()
    });

  }


  textForCustomer(data: any): string {

    var returnValue = "--"

    returnValue = data.displayName || data.email

    returnValue = "null null" === returnValue ? data.email : returnValue

    return returnValue

  }

  imageForCustomer (data: any): string {

    var returnValue = "/assets/img/login.png"

    if (data.img) {
      returnValue = data.img
    }

    returnValue = "null null" === returnValue ? data.customer.email : "/assets/img/login.png"

    return returnValue

  }


  public showAlert (title: string, message: string) {

    swal.fire({
      title: title,
      text: message
    })

  }

  priceNumberForProduct (currentProduct: any): number {
    return currentProduct.price

    if (currentProduct.price_discount){
      return currentProduct.price
    }

    return currentProduct.price_discount < currentProduct.price ? currentProduct.price_discount : currentProduct.price;
  }

  priceForProduct (currentProduct: any): string {

    //console.log("priceForProduct ", currentProduct);

    return currentProduct.price_str;

    if (currentProduct.price_discount ){
      return currentProduct.price_str;
    }
    return currentProduct.price_discount < currentProduct.price ? currentProduct.price_discount_str : currentProduct.price_str;
  }


  public userPointsToShow(): string {

    return String(this.userPoints)
    //return this.userPoints.toLocaleString('es-CL') //new Intl.NumberFormat('es-CL', { maximumSignificantDigits: 3, useGrouping: true }).format(this.userPoints));

  }

   public googleInit() {

    gapi.load('auth2', () => {

      this.auth2 = gapi.auth2.init({
        client_id: '95649798878-ldd56r061bjhuoajtjsvp5fdcq504jsa.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });

      this.attachSignin(document.getElementById('googleBtn'));

    });

  }

  public attachSignin(element: HTMLElement | null) {
    this.auth2.attachClickHandler(element, {},
      (googleUser: { getBasicProfile: () => any; getAuthResponse: () => { (): any; new(): any; id_token: string; }; }) => {

        let profile = googleUser.getBasicProfile();

        console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());

        //YOUR CODE HERE

      }, (error: any) => {
        alert(JSON.stringify(error, undefined, 2));
      });
  }

    ngOnDestroy() {

        console.log('ngOnDestroy');

        this.cartSubscription?.unsubscribe()
        this.userSubscription?.unsubscribe()

    }

    public goUp() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    @HostListener('window:unload', [ '$event' ])
    unloadHandler(event: any) {
        console.log('unloadHandler')
        this.ngOnDestroy()

    }

    @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHandler(event: any) {
        console.log('beforeUnloadHandler')
        this.ngOnDestroy()
    }

    facebookUrl (): string {
      return ""
    }

    twitterUrl (): string {
      return ""
    }

    instagramUrl (): string {
      return ""
    }


}
