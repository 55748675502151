<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<!-- Toolbar -->


<router-outlet></router-outlet>

  <html class="bg-body" lang="es">
    <head>

      <script src="js/jquery.js"></script>
      <script src="js/jquery-ui.js"></script>
      <script src="js/modernizr.min.js"></script>
      <script src="js/bootstrap.min.js"></script>
      <script src="js/wow.min.js"></script>
      <script src="js/jquery.parallax.js"></script>
      <script src="js/owl.carousel.js"></script>
      <script src="js/scripts.js"></script>

      <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>

      <meta name="robots" content="index, follow" />
      <meta name="keywords" content="" />
      <meta name="description" content="" />

      <title>Pascucci</title>

      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
      <meta http-equiv="expires" content="wed, 30 jul 2014 14:30:00 GMT">

      <meta property="og:site_name" content=""/>
      <meta property="og:title" content=""/>
      <meta property="og:description" content=""/>
      <meta property="og:url" content="" />
      <meta property="og:image" content=""/>

      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;700&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap" rel="stylesheet">

      <!--[if lt IE 9]>
        <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
        <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
      <![endif]-->

    </head>
    <body>
    <ng-container *ngIf="loading; else loader">
      <div *ngIf="loading; else empty">

        <body class="bg-body">

<notifier-container></notifier-container>


<!--   <a href="javascript:void(0)" class="blisspoins-btn wow animate fadeInLeft" data-wow-duration="1s" data-wow-delay=".5s">
    <p>
      Mis ptos.
      <span>{{userPointsToShow()}}</span>
    </p>
  </a>
 -->
         <!--  <header class="bg-header wow animate fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
            <h1>
              Logo Pascucci
              <a class="bg-header" [routerLink]="['/']">
                <img src="/assets/img/logo.png" alt="">
              </a>
            </h1>

            <ng-container *ngIf="'Guest' != user.displayName; else guess_user">
              <nav class="bg-header">
                <ul>
                  <li><a [routerLink]="['/history']">Mis pedidos</a></li>
                  <li><a href="mailto:contacto@riconarabe.cl">Contacto</a></li>
                  <li><a [routerLink]="['/stores']">Tiendas</a></li>
                  <li><a [routerLink]="['/addresses']">Direcciones</a></li>
                  <li><a (click)="logout()">Cerrar sesión</a></li>
                </ul>
              </nav>
            </ng-container>
            <ng-template #guess_user>
              <nav class="bg-header">
                <ul>
                  <li><a href="mailto:contacto@riconarabe.cl">Contacto</a></li>
                  <li><a [routerLink]="['/stores']">Tiendas</a></li>
                </ul>
              </nav>
            </ng-template>

            <a [routerLink]="['/cart']" class="car">
              <img src="/assets/img/car.png" alt="">
              <span>{{cartNumberProducts}}</span>
            </a>

            <a class="login" (click)="loginWithGoogle()">
              <img src="/assets/img/login.png" alt="">
            </a>

            <a class="login-perfil" (click)="loginWithGoogle()">
              <p>{{(textForCustomer(user))}}</p>/category/2yE5cqqRFUM4JAp66f5T
              <img src={{imageForCustomer(user)}} alt="">
            </a>

            <button class="burger">
              <span></span>
              <span></span>
              <span></span>
            </button>

          </header>
 -->
 <header class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay=".5s">
  <h1><a (click)="goUp()" style="cursor: hand;cursor: pointer;"></a></h1>

  <button class="burger">
    <img src="/assets/img/logo.png" alt="">
    <span></span>
    <span></span>
      <span></span>
  </button>

  <div class="cont-lupa">
    <input class="buscar" type="text" id="name" name="name" placeholder="Buscar" required />
  </div>

  <a [routerLink]="['/cart']" class="carrito" href="carrito-compras.html">
    <span class="contador">{{cartNumberProducts}}</span>
    <img src="images/carrito.svg" alt="">
  </a>

  <ng-container *ngIf="'Guest' != user.displayName; else guess_user">
    <a (click)="logout()" style="cursor: hand;cursor: pointer;" class="tienda">Cerrar sesión</a>
  </ng-container>
  <ng-template #guess_user>
    <a (click)="loginWithGoogle()"  style="cursor: hand;cursor: pointer;" class="tienda">Inicia Sesión</a>
  </ng-template>

  <img class="bandera" src="images/bandera.png" alt="">
</header>

<nav class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay=".5s">
  <div class="box">
    <div *ngFor="let family of families" class="box-inner">
      <a (click)="selectFamily(event, family)" style="cursor: hand;cursor: pointer;">{{family.name}}</a>
    </div>
  </div>
</nav>

<main>

  <ng-container *ngIf="null != selectedProduct; else normal_product_list">
    <section class="single-producto wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
      <div class="container">
        <div class="row">

          <div class="col-12 col-md-7 col-lg-6">
            <div class="caja bg">
              <div class="contenido active" data-contenido="uno">
                <figure>
                  <img src= {{this.imgForProduct()}} alt="">
                </figure>
              </div>

              <ng-container *ngIf="this.imgsForProductLength() > 1; else img_1">
                <div></div>
                </ng-container>
                <ng-template #img_1>
                  <div class="contenido" data-contenido="dos">
                    <figure>
                      <img src= {{this.imgsForProduct()[1]}} alt="">
                    </figure>
                  </div>
                </ng-template>

                <ng-container *ngIf="this.imgsForProductLength() > 2; else img_2">
                  <div></div>
                  </ng-container>
                  <ng-template #img_2>
                    <div class="contenido" data-contenido="tres">
                      <figure>
                        <img src= {{this.imgsForProduct()[2]}} alt="">
                      </figure>
                    </div>
                  </ng-template>

                  <ng-container *ngIf="this.imgsForProductLength() > 3; else img_3">
                    <div></div>
                    </ng-container>
                    <ng-template #img_3>
                      <div class="contenido" data-contenido="cuatro">
                        <figure>
                          <img src= {{this.imgsForProduct()[3]}} alt="">
                        </figure>
                      </div>
                    </ng-template>


            </div>

            <a href="javascript:void(0);" class="btn-contenido" data-contenido="uno">
              <figure>
                <img src= {{this.imgForProduct()}} alt="">
              </figure>
            </a>

            <ng-container *ngIf="this.imgsForProductLength() > 1; else img_1_selected">
              <div></div>
              </ng-container>
              <ng-template #img_1_selected>
                <a href="javascript:void(0);" class="btn-contenido" data-contenido="dos">
                  <figure>
                    <img src= {{this.imgsForProduct()[1]}} alt="">
                  </figure>
                </a>
              </ng-template>


              <ng-container *ngIf="this.imgsForProductLength() > 2; else img_2_selected">
                <div></div>
                </ng-container>
                <ng-template #img_2_selected>
                  <a href="javascript:void(0);" class="btn-contenido" data-contenido="tres">
                    <figure>
                      <img src= {{this.imgsForProduct()[2]}} alt="">
                    </figure>
                  </a>
                </ng-template>

                <ng-container *ngIf="this.imgsForProductLength() > 3; else img_3_selected">
                  <div></div>
                  </ng-container>
                  <ng-template #img_3_selected>
                    <a href="javascript:void(0);" class="btn-contenido" data-contenido="cuatro">
                      <figure>
                        <img src= {{this.imgsForProduct()[3]}} alt="">
                      </figure>
                    </a>
                  </ng-template>
          </div>

          <div class="col-12 col-md-5 col-lg-6">
            <div class="box-texto">
              <h3 class="single-title-producto">{{this.selectedProduct.name}}</h3>
              <h4 class="single-desc-producto">{{this.descriptionForProduct()}}</h4>
              <h5 class="single-precio-producto">{{this.price()}}</h5>

              <p>{{this.selectedProduct.description_long}}</p>
            </div>


              <ng-container *ngIf="!this.hasModificators(); else modificator_layout">
              <div></div>
              </ng-container>
              <ng-template #modificator_layout>
                <div class="info-extra">
                    <div *ngFor="let modificator of this.modificatorsForProduct(this.selectedProduct)" class="acordion">
                      <a href="javascript:void(0);" class="header-acordion">
                        {{modificator.name}}
                        <span></span>
                        <span></span>
                      </a>
                      <div class="contenido-acordion">
                        <form>
                          <ul>
                            <li *ngFor="let product of this.modificatorsProductForModificator(modificator)">
                              <input type="checkbox" [checked]="isModificatorProductSelected(product)" (change)="selectModificatorProduct(modificator, product)">
                              <label>&nbsp;{{product.name}} <span>{{this.priceForModificatorProduct(modificator, product)}}</span></label>
                            </li>
                          </ul>
                        </form>
                      </div>

                  </div>
                </div>
              </ng-template>

            <!-- <div class="info-extra">
              <div class="acordion">
                <a href="javascript:void(0);" class="header-acordion">
                  Agregar Bebida
                  <span></span>
                  <span></span>
                </a>
                <div class="contenido-acordion">
                  <form>
                    <ul>
                      <li>
                        <input type="checkbox" id="" value=""/>
                        <label>Coca Cola 350cc</label>
                      </li>
                      <li>
                        <input type="checkbox" id="" value=""/>
                        <label>Fanta 350cc</label>
                      </li>
                      <li>
                        <input type="checkbox" id="" value=""/>
                        <label>Pepsi 350cc</label>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>

              <div class="acordion">
                <a href="javascript:void(0);" class="header-acordion">
                  Agregar Salsa
                  <span></span>
                  <span></span>
                </a>
                <div class="contenido-acordion">
                  <form>
                    <ul>
                      <li>
                        <input type="checkbox" id="" value=""/>
                        <label>Salsa 1</label>
                      </li>
                      <li>
                        <input type="checkbox" id="" value=""/>
                        <label>Salsa 2</label>
                      </li>
                      <li>
                        <input type="checkbox" id="" value=""/>
                        <label>Salsa 3</label>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div> -->

            <div class="box-btns">
              <div class="calculadora">
                <div class="valor">
                  <span class="numero">{{count}}</span>
                </div>
                <div class="calc">
                  <a (click)="add()" style="cursor: hand;cursor: pointer;">+</a>
                  <a (click)="substract()" style="cursor: hand;cursor: pointer;">-</a>
                </div>
              </div>

              <a (click)="addToCart()" style="cursor: hand;cursor: pointer;" class="ver-btn">Agregar</a>
              <a (click)="unSelectProduct(event, product)" style="cursor: hand;cursor: pointer;" class="ver-btn continuar">< Continuar comprando</a>
            </div>

          </div>

        </div>

      </div>
    </section>

    <section class="productos_destacados wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
      <div class="container">
        <div class="row">

          <div class="col-12">

          </div>
        </div>

      </div>
    </section>

    <section class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 p-0">
            <div class="separador"></div>
          </div>
        </div>
      </div>
    </section>


  </ng-container>
  <ng-template #normal_product_list>




    <section class="home wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">

      <owl-carousel-o [options]="{
            loop: true,
            rewind: true,
            rewindNav: false,
            lazyLoad: true,
            dots: false,
            autoplay: true,
            items: 1,
            autoplayTimeout: 6000,
            autoplayHoverPause: false,
            autoplaySpeed: 3000,
            dotsContainer: '#carousel-custom-dots'
        }">

<ng-container *ngFor="let promotion of ['images/banner-home-1.png','images/banner-home-2.png']">
  <ng-template carouselSlide [id]="promotion">

    <div class="container-fluid">
            <div class="item">

      <figure class="imgLiquid d-none d-md-block"><img src={{promotion}} alt=""></figure>
    </div>

</div>

  </ng-template>
    </ng-container>


  </owl-carousel-o>
</section>



    <section class="productos_destacados wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ng-container *ngIf="null != selectedFamily; else no_selected_family">
              <h2 class="section-title"><strong>{{selectedFamily.name}}</strong></h2>
            </ng-container>
            <ng-template #no_selected_family>
                <ng-container *ngIf="null != selectedCategory; else no_selected_category">
                  <h2 class="section-title"><strong>{{selectedCategory.name}}</strong></h2>
                </ng-container>
                <ng-template #no_selected_category>
                  <h2 class="section-title">Productos <strong>Destacados</strong></h2>
                </ng-template>
            </ng-template>
          </div>
        </div>
        <div class="row">
          <div class="d-none d-lg-block col-lg-3">
            <div class="desk-left">
              <div class="box">
                <div class="box-inner">

                  <div *ngFor="let family of families" class="acordion-menu-left">


                    <ng-container *ngIf="family.categoriesData.length > 0; else alone">
                        <a href="javascript:void(0);" class="header-acordion">
                          {{family.name}}
                          <span></span>
                          <span></span>
                        </a>
                        <div class="contenido-acordion">
                          <div *ngFor="let category of family.categoriesData">
                            <a (click)="selectCategory(event, category)" style="cursor: hand;cursor: pointer;">{{category.name}}</a>
                          </div>
                        </div>
                    </ng-container>
                    <ng-template #alone>
                      <a class="btn-alone">{{family.name}}</a>

                    </ng-template>

                  </div>


                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-9">

            <ng-container *ngFor="let product of productsToShow()">


                <a class="produtos" [routerLink]="" (click)="selectProduct(event, product)" style="cursor: hand;cursor: pointer;">
                  <figure><img src={{product.imgs[0]}} alt=""></figure>
                  <h3 class="nombre-prod">{{product.name}}</h3>
                  <h4 class="precio-prod">{{priceForProduct(product)}}</h4>
                </a>


            </ng-container>


          </div>

        </div>


        <ng-container *ngIf="null == selectedCategory && null == selectedFamily; else view_all_btn">
            <div class="row ver-mas-noticias">
              <div class="col-12">
                <a (click)="toggleViewAll()" style="cursor: hand;cursor: pointer;" class="ver-btn">{{viewMoreButtonTitle()}}</a>
              </div>
            </div>
        </ng-container>
        <ng-template #view_all_btn>
          <div></div>
        </ng-template>



      </div>

    </section>

    <ng-container *ngIf="null != selectedProduct; else normal_product_separator_list">

      <section class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 p-0">
              <div class="separador"></div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-template #normal_product_separator_list>
      <div></div>
     </ng-template>

</ng-template>



<ng-container *ngIf="null != selectedProduct; else normal_product_recommended_list">

  <section class="productos_destacados wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="section-title">Productos <strong>Recomendados</strong></h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">


          <ng-container *ngFor="let product of this.productsRecommended">


            <a class="produtos" [routerLink]="" (click)="selectProduct(event, product)">
              <figure><img src={{product.imgs[0]}} alt=""></figure>
              <h3 class="nombre-prod">{{product.name}}</h3>
              <h4 class="precio-prod">{{priceForProduct(product)}}</h4>
            </a>


        </ng-container>

        </div>
      </div>

    </div>
  </section>
</ng-container>
<ng-template #normal_product_recommended_list>
  <div></div>
 </ng-template>


  <section class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 p-0">
          <div class="separador"></div>
        </div>
      </div>
    </div>
  </section>




</main>






<footer class="wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
  <div class="container">

    <div class="row">
      <div class="col-12 col-md-3">
        <img class="corazon" src="images/corazon.png" alt="">
      </div>

      <div class="col-12 col-md-3">
        <h2>Contacto Venta y Post venta</h2>
        <p>
          <a href="mailto:contacto@pascucci.cl">contacto@pascucci.cl</a><br>
          WhatsApp: +56 9 9 2303318<br><br>

          <a href="javascript:void(0)">Términos y condiciones</a><br>
          <a href="javascript:void(0)">Políticas de devolución y reembolso</a>
        </p>
      </div>

      <div class="col-12 col-md-3">
        <h2>Venta empresas y convenios</h2>
        <p>
          <a href="mailto:monica.concha@pascucci.cl">monica.concha@pascucci.cl</a><br>
          WhatsApp: +56 9 9 2303318<br><br>

          <a href="mailto:flor.ormino@pascucci.cl">flor.ormino@pascucci.cl</a><br>
          WhatsApp: +56 9 94438263
        </p>
      </div>

      <div class="col-12 col-md-3">
        <h2>Menú</h2>
        <p>
          <a href="https://www.pascucci.cl/nosotros.html" target="_blank">Nuestra historia</a><br>
          <a href="https://www.pascucci.cl/nosotros.html" target="_blank">Trazabilidad y comercio justo</a><br>
          <a href="https://www.pascucci.cl/locales.html" target="_blank">Nuestras tiendas</a><br>
          <a href="javascript:void(0)">Nuestra carta</a><br>
          App <a class="icos" href="#" target="_blank"><img src="images/apple.png" alt=""></a> <a class="icos" href="https://play.google.com/store/apps/details?id=cl.nhm.app.app.pascucci&hl=es_PY&pli=1" target="_blank"><img src="images/googleplay.png" alt=""></a>
          <a href="https://www.pascucci.cl/franquicia.html" target="_blank">Franquicias</a><br>
          <a href="https://www.pascucci.cl/trabaja.html" target="_blank">Trabaja con nosotros</a>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <h2>Síguenos</h2>
        <ul class="rrss">
          <li><a href="https://www.facebook.com/caffepascuccichile" target="_blank"><img src="images/icoface.webp" alt=""></a></li>
          <li><a href="https://twitter.com/pascucci_CL" target="_blank"><img src="images/icotwitter.webp" alt=""></a></li>
          <li><a href="https://www.youtube.com/user/pascucciespresso" target="_blank"><img src="images/icoyoutube.webp" alt=""></a></li>
          <li><a href="https://www.instagram.com/pascucci_cl/" target="_blank"><img src="images/ico_instagram.webp" alt=""></a></li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        <ul class="sellos">
          <li><img src="images/sello-1.png" alt=""></li>
          <li><img src="images/sello-2.png" alt=""></li>
          <li><img src="images/sello-3.png" alt=""></li>
          <li><img src="images/sello-4.png" alt=""></li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        <img class="tarjetas" src="images/tarjetas.png" alt="">
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="text-center">2024 Texto marca registrada Texto marca registrada Texto marca registrada Texto marca registrada Texto marca registrada</p>
      </div>
    </div>
    <div class="row arrow-bottom">
      <div class="col-12">
        <a href="javascript:void(0)" class="arrow-top">
          <span></span>
          <span></span>
        </a>
      </div>
    </div>
  </div>
</footer>









    </body>


    <script>
      function selectProduct(e, product) {
        console.log('x')

          // ...  your function code
          // e.preventDefault();   // use this to NOT go to href site
      }
  </script>


<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


