import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Constants } from 'src/environments/environment';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from  '@angular/common/http';
import { HmacSHA256 } from "crypto-js";
import swal from 'sweetalert2';
import { FirestoreService } from '../services/firestore.service';
import { DataService } from '../services/data.service';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { formatCurrency, registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/es-CL';
import { AuthService } from '../services/auth.service';
import { MessagingService } from '../services/messaging.service';
import { NotifierService } from 'angular-notifier';
import { windowTime } from 'rxjs/operators';
import { Console } from 'console';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
  })

  export class CartComponent extends BaseComponent {
    TRANSFER_PAY_TEXT = '';

/*
    TRANSFER_PAY_TEXT = '<b>Por favor transfiere a:</b> <br>' +
    '<br>' +
    '<b>Banco:</b> Banco Itau <br>' +
    '<b>Tipo de Cuenta:</b> Cuenta Corriente <br>' +
    '<b>Nombre o Razón Social:</b> rincon arabe spa <br>' +
    '<b>Nº de cuenta:</b> 0220907721 <br>' +
    '<b>Rut asociado a la cuenta bancaria:</b> 77.266.408-7 <br>' +
    '<br>' +
    '<b>Gracias</b>';
    */
    PAY_TEXT =
    'Por favor paga en la caja cuando vayas a retirar tu orden. <br>' +
        '<br>' +
        '<b>Gracias</b>';


    public total_str = "$0"

    popUp: Window | null = null

    public cartData: any = {}
    public productsFiltered: Array<any> = [];
    public addresses: Array<any> = [];
    public selectedAddress: any | null = null;
    public selectedStore: any | null = null;
    public phoneNumber: string | null = null;
    public stores: Array<any> = [];

    paymentTypeButtonText = "Forma de Pago";

    deliveryPrice = 0;
    userPointsDiscountApplied = false;

    initialLoading = true;

    hasPhoneNumber = false;
    orderedCompleted = false;

    commerceOrderDataOrderId = '';

    paymentType = -1;
    deliveryType = -1;

    public paymentTypes = ["Crédito / Débito", "Transferencia", "Efectivo"]
    public deliveryTypes = ["Retiro en tienda", "Delivery"]
    public validDistricts: Array<any> = [{"title": "Macul", "price": 3000},
                                          {"title": "Peñalolen", "price": 5000},
                                          {"title": "Ñuñoa", "price": 2000},
                                          {"title": "Providencia", "price": 3000},
                                          {"title": "Las condes", "price": 5000},
                                          {"title": "La reina", "price": 2000}];



    constructor(
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef,
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService,
      private route: ActivatedRoute,
      private router: Router,
      private location: Location ) {

        super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);

        registerLocaleData(locale, 'cl');

        this.someFunctionToCallWhenPopUpCloses();

    }

    async loadData () {

      super.loadData();

      this.stores = await this.dataService.currentStores();

      this.addresses = this.user.addresses || [];
      if (this.addresses.length > 0) {
        this.selectedAddress = this.addresses[0];
      }

      this.phoneNumber = this.user.phone || null;

      await this.checkPhone();

/*
      if (!this.checkPhone()) {
        this.requestPhone ()
      }
*/
    }

    requestPhone () {

console.log("requestPhone")

      Swal.fire({
        title: 'Por favor necesitamos tú número de teléfono, solo te llamaremos si es necesario.',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return fetch(`//api.github.com/users/${login}`)
            .then(response => {
              if (!response.ok) {
                throw new Error(response.statusText)
              }
              return response.json()
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url
          })
        }
      })
    }

    obtainProductsFiltered () {

      this.productsFiltered = (this.cart.products || []).sort().filter(function(item: any, pos: number, ary: any[]) {
        return !pos || item != ary[pos - 1];
      });

    }

    showLoading():boolean {

      var status = this.cart?.status || 0

      return status == 6 && this.loading

    }

    async cartReady() {

      super.cartReady()
      //console.log('cartReady');
      //console.log('this.cart', this.cart);
/*
     console.log('this.cart.products', this.cart.products);
     console.log('this.products', this.products);
     */
/*
      if (this.promotions.length == 0) {
        this.promotions = await this.dataService.currentPromotions();
      }
*/
      if (this.products?.length || 0 == 0 && this.cart.products?.length || 0 > 0) {

        const previosProductsFiltered = this.productsFiltered;
        this.obtainProductsFiltered()/*
        console.log('previosProductsFiltered', previosProductsFiltered);
        console.log('this.productsFiltered', this.productsFiltered);
*/
        if (!this.compare(previosProductsFiltered, this.productsFiltered)) {

          //console.log('update this.products');

          this.products = await this.dataService.productsByCategory([...this.productsFiltered] as [string], false);

        }

        console.log(this.products);

        this.processData ();
        this.initialLoading = false;

      }



    }

    productById (uid: string): any {
      return this.products?.find(function(product) { return product.uid == uid });
    }

    totalForProduct (uid: string): string {

      const product = this.productById(uid);
      const amount = this.cartData[uid]

      const price = this.priceNumberForProduct(product);

      return formatCurrency(amount * price, "cl", '$', 'CLP')

    }

   compare(array1: any[], array2: any[]){
    if(array1.length !== array2.length) { return false }
      array1.sort();
      array2.sort();
      for (var i = 0; i < array1.length; i++){
        if (array1[i] !== array2[i]) return false;
      }
      return true;
    }


    private processData () {

      this.cartData = {};

      this.cartData = (this.products || []).reduce((accumulator: any, product: { uid: any; price: number; price_discount: number; }) =>{

        const uid = product.uid

        const count = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === uid) ? ++pre : pre, 0)

        var data = accumulator

        const total = data.total ?? 0

        const price = this.priceNumberForProduct(product);

        const value = price ?? 0;

        data.total = (value * count + total)

        data[uid] = count

        return data

      }, {})

     console.log("cartData:", this.cartData);

      this.total_str = formatCurrency(this.cartData.total ?? 0, 'cl', '$', 'CLP')

    }

    textForAddress (address: any): string {

      var value: any = null
/*
      for (var i = 0, len = address.address_components?.length || 0; i < len; i++) {

        const element = address.address_components[i]

        if (element?.types.find(function (type: string | string[]) {
          return type.includes('administrative_area_');
        })) {

          const long_name = element.long_name.toUpperCase()
          const short_name = element.short_name.toUpperCase()


         value = this.validDistricts.find(function (district) {

            const districtVal = district.title.toUpperCase()

            return districtVal.includes(long_name) || districtVal.includes(short_name);

          })

          if (value) {
            break
          }

        }

      }

      var returnValue = address.name

      if (value) {
*/
      var returnValue = ""

        let lat1 = this.store.address?.lat || 0.0
        let lng1 = this.store.address?.lng || 0.0

        let lat2 = address.lat || 0.0
        let lng2 = address.lng || 0.0

        let distance: number = Math.round (this.calcCrow (lat1, lng1, lat2, lng2))

        //console.log("distance:", distance);


        var price:number = 1000

        let baseDelivery = this.store.deliveryPricesData.base
        let eachKmAmount: number = this.store.deliveryPricesData.each_km_amount
        let maxDelivery = this.store.deliveryPricesData.max


        price = price < baseDelivery ? baseDelivery : price
        price = price > maxDelivery ? maxDelivery : price

        price = distance * eachKmAmount

/*
        if (distance <= 1.0) {
          price = 1000
        } else if (distance > 1.0 && distance <= 2.0) {
          price = 2000
        } else if (distance > 2.0 && distance <= 3.0) {
          price = 3000
        } else if (distance > 3.0 && distance <= 4.0) {
          price = 4000
        }else {
          price = 5000
        }
*/

        returnValue = address.name  + " - (Delivery " + formatCurrency(price, 'cl', '$', 'CLP') + ")"

 /*     }else {
        returnValue = address.name  + " - (Delivery $2.000)"

      }
*/

      return returnValue

    }

    totalNum(): number {

      var total = this.cartData.total ?? 0;

      const modificators = this.cart.modificators || {};

      const totalModificators = Object.keys(modificators)
      .reduce ( (acc: number, key: string) => {

        const data = modificators[key] || [];
        const count = Number(this.cartData[key] || 0);

        const subAmount = data.reduce( (subAcc: number, subData: any) => {
          const price = Number(subData.price || 0);
          return subAcc + price;
        }, 0) || 0;

        return acc + subAmount * count;
       }, 0) || 0;


      total += totalModificators;

      const discount = this.cart.discount ?? 0;
      const userPointsDiscount = this.userPointsDiscountApplied ? this.userPoints : 0;

      const cartDeliveryPrice = this.deliveryPrice ?? 0;

      var value = total + discount + cartDeliveryPrice - userPointsDiscount;
      value = value < 0 ? 0 : value;

      return value;

    }

    totalDiscount(): string {

      var value = this.totalNum();

      const discount = this.cart.discount ?? 0;
      const userPointsDiscount = this.userPointsDiscountApplied ? this.userPoints : 0

      value = value + -discount + userPointsDiscount;




      return formatCurrency(value, 'cl', '$', 'CLP');

    }

    total(): string {

      const value = this.totalNum();

      return formatCurrency(value, 'cl', '$', 'CLP')

    }


    showDiscount () {

      const discount = this.cart.discount ?? 0
      const userPointsDiscount = this.userPointsDiscountApplied ? this.userPoints : 0

      return (-discount + userPointsDiscount) > 0

    }


    changeTypeDelivery(type: number) {
  //    console.log('changeTypeDelivery', type);
    }

    changeTypePayment(type: number) {
  //    console.log('changeTypePayment', type);
      this.paymentTypeButtonText = this.paymentTypes[type];
      this.paymentType = type;
    }

    private addressByName (name: any) {
      return this.addresses.find(function (address) {
         return address.name == name;
     });
   }


    selectAddress (e: any) {

      var address = this.addressByName(e.target.value)

      if (address) {
        this.selectedAddress = address

        if (2 == this.deliveryType) {
          this.deliveryPrice = this.obtainDeliveryPrice();
        }

      }

    }

    backAction() {
       this.location.back();
     }


    selectStore (e: any) {
      console.log(e.target.value);

      var store = this.stores.find(function (item) {
        return item.name == e.target.value;
      });
      console.log(store);
      if (store) {
        this.selectedStore = store;
      }

    }

    updateCart (newCart: any) {

  //    console.log('updateCart');

      const previousUID = this.cart?.uid || null
      const newUID = newCart.uid || null

 //     console.log('previousUID', previousUID);
 //     console.log('newUID', newUID);

      const status = this.cart?.status || 0

      const changedStatus = (1 == status || 6 == status) && 2 == newCart.status


           console.log('changedStatus', changedStatus);

      if (changedStatus) {

        this.orderedCompleted = true

        //this.showChangedStatusAlert ();

        this.loading = false

        this.cart = newCart

        setTimeout(() =>
          {
            this.backAction();
          },
          10000);


      }else {

        this.cart = newCart

        if (previousUID !== null && newUID !== null && previousUID != newUID) {
          this.products = []
      //    console.log('dfferent uid');

        }

      }

    }

    public showChangedStatusAlert () {

      swal.fire({
        title: 'Aviso',
        text: 'Hemos recibido tú pedido.',
        confirmButtonText: `Ok`,
      }).then((result) => {

        this.backAction();

      });

    }

    fieldsChange(values:any):void {
      console.log(values.currentTarget.checked);
    }

    userPointsText (): string {

      return formatCurrency(this.userPoints ?? 0, 'cl', '$', 'CLP');

    }

    addressForStore() {
      return this.selectedProduct?.description || '';
    }

    phoneForStore() {
      return this.selectedProduct?.description || '';
    }

    onChangeDiscount(e: any ){

      const value = e.target.checked

      this.userPointsDiscountApplied = value;

    }

    obtainDeliveryPrice (): number {

      var returnValue = 2000

      var value = null

        let lat1 = this.store.address?.lat || 0.0
        let lng1 = this.store.address?.lng || 0.0

        let lat2 = this.selectedAddress.lat || 0.0
        let lng2 = this.selectedAddress.lng || 0.0

        let distance = this.calcCrow (lat1, lng1, lat2, lng2)

        let price = 1000

        console.log('distance', distance)

        if (distance > 1.0 && distance <= 2.0) {
          price = 2000
        } else if (distance > 2.0 && distance <= 3.0) {
          price = 3000
        } else if (distance > 3.0 && distance <= 4.0) {
          price = 4000
        }else {
          price = 5000
        }

        returnValue = price

   //   }


      return returnValue

    }

    onChange(e: any ){

      console.log(this.selectedAddress)

      const value = Number(e.target.name)

      if (value != this.deliveryType) {

        this.deliveryType = value

        switch (value) {
            case 2:
            this.deliveryPrice = this.obtainDeliveryPrice();
            break
          default:
            this.deliveryPrice = 0;
            break
        }

      }else {
        this.deliveryType = -1
        this.deliveryPrice = 0;
      }

   //   console.log("deliveryPrice", this.deliveryPrice);
    //  console.log("deliveryType", this.deliveryType);

    }

    ngOnDestroy(): void {
    //  console.log('cart destroy');
    }

    someFunctionToCallWhenPopUpCloses() {

      var loop = setInterval(() => {

        const status = this.cart?.status || 0


        if ((this.popUp?.closed || false) && this.loading && (1 == status || 6 == status)) {
            clearInterval(loop);
            this.loading = false
            this.showAlert('Aviso', "Ha ocurrido un error al pagar.")
          }

    }, 1000);

  }

  checkIfInPromotion (uid: string) {

   const promotion =  this.cart.promotions?.find (function(promotion: any) {

      const productA = promotion.productsA[0] || ""
      const productB = promotion.productB

      return (uid == productA || uid == productB)

     })

     return promotion

  }

  substract (uid: string) {

    //  console.log("substract", uid);

      const promotion =  this.checkIfInPromotion(uid)

      if (promotion) {
        console.log("promotion", promotion);

        swal.fire({
          title: 'Aviso',
          text: "Este item corresponde a la promoción " + (promotion.name || "") + " estás seguro que lo deseas eliminar?",
          showCancelButton: true

        }).then((result) => {
          if (result.isConfirmed) {

            this.substractPromotion (promotion)

          }
        })


      }else {
        this.substractProduct(uid)
      }
/*
      var product = null
      if (this.cartData[uid] == 1) {
        const index: number = this.products!.find(function(product: { uid: any; }) { return product.uid == uid });
        if (index !== -1) {
          product = this.products[index];
          this.products.splice(index);
        }
      }

      const price = this.priceNumberForProduct(product) || 0

      this.dataService.removeFromCart(uid, 1, price)
*/
    }

    remove (uid: string) {

      var product = null;
      const index: number = this.products!.findIndex(function(product: { uid: any; }) { return product.uid == uid });
      if (index !== -1) {
        product = this.products[index];
      }

      let count = this.cartData[uid];

      const price = this.priceNumberForProduct(product) || 0;

      this.products = this.products.filter((item) => item.uid != uid);

      this.dataService.removeAllFromCart(uid, count, price);

    }

    substractPromotion (promotion: any) {

      const productA = promotion.productsA[0] || ""
      const productB = promotion.productB || ""

      const amountA = promotion.amountA || 1

      if (this.cartData[productA] == amountA) {
        for (let i = 0; i < amountA; i++) {
          const index: number = this.products!.find(function(product: { uid: any; }) { return product.uid == productA });
          if (index !== -1) {
            this.products.splice(index);
          }
        }

      }

      if (this.cartData[productB] == 1) {
        const index: number = this.products!.find(function(product: { uid: any; }) { return product.uid == productB });
        if (index !== -1) {
          this.products.splice(index);
        }
      }

      this.dataService.removePromotionFromCart(promotion)

    }

    substractProduct (uid: string) {

      var product = null
      if (this.cartData[uid] == 1) {
        const index: number = this.products!.findIndex(function(product: { uid: any; }) { return product.uid == uid });
        console.log(index)

        if (index !== -1) {
          product = this.products[index];
          this.products.splice(index);
        }
      }else {
        product = this.productById(uid);
      }

      console.log(product);

      if (product) {

        const price = this.priceNumberForProduct(product) || 0;
        this.dataService.removeFromCart(uid, 1, price);

       }

    }

    add (uid: string) {

      console.log("add", uid);
      console.log("substract", uid);
      var product = this.productById(uid);
/*
      if (this.cartData[uid] == 1) {
        const index: number = this.products!.find(function(product: { uid: any; }) { return product.uid == uid });
        if (index !== -1) {
          product = this.products[index];
        }   Simbo
      }
      */

      const amount = this.cartData[uid]

      if (amount + 1 > product.stock) {
        this.showAlert('Aviso', "Mayor que stock disponible")
      }else {
        const price = this.priceNumberForProduct(product) || 0
        this.dataService.addToCart(uid, 1, price, {});
      }

    }

    public async buy(event: any) {

      this.deliveryType = 1;
      this.paymentType = 0;

      await this.checkPhone();

     /* if (!this.hasPhoneNumber) {

        this.showAlert('Aviso', "Por favor ingresa un número de teléfono válido.")

      }else if (!this.isOpen) {

        this.showAlert('Aviso', "Lo sentimos pero ya cerramos.")

      }else*/ if (-1 === this.deliveryType) {

        this.showAlert('Aviso', "Por favor, selecciona un tipo de despacho.")

      }else if (2 === this.deliveryType && null == this.selectedAddress) {

        this.showAlert('Aviso', "Por favor, selecciona una dirección.")

      }else {

        const current = new Date();

        this.cart.status = 6
        this.loading = true

        let index = this.cart.index || 0

        if (index == 0) {

          let indexData = await this.dataService.indexForOrder(this.user.uid || "")

          let nIndex = indexData.index || 0

          console.log("index", nIndex)


          if (nIndex > 0) {
            this.cart.index = nIndex
          }

        }

        try {
          let commerceOrderData = await this.dataService.orderIdForUser(this.user.uid || "")

          console.log("commerceOrderData", commerceOrderData)
          let commerceOrder = commerceOrderData.orderId || "sf" + current.getTime()

          this.commerceOrderDataOrderId = commerceOrder;

          this.cart.commerceOrder = commerceOrder
          this.cart.updated = Date.now()
          this.cart.user = this.user.uid || ""
          this.cart.amount = Number(this.totalNum());

          if (this.userPointsDiscountApplied) {
           this.cart.userPointsDiscountApplied = Number(this.userPoints);
          }

          var deliveryData: any = {}

          console.log("this.deliveryType", this.deliveryType);

          if (this.hasPhoneNumber) {
            this.user.phone = this.phoneNumber;

            await this.firestoreService.updateCustomer(this.user.uid, this.user)
            this.dataService.updateCurrentUser(this.user, this.authService.currentUser)
          }


          switch (Number(this.deliveryType)) {
            case 2:{
              deliveryData['type'] = 2
              deliveryData['address'] = this.selectedAddress
            }
              break
            case 1:{
              deliveryData['type'] = 1
            }
              break
            default:
              break
          }

          this.cart.delivery = deliveryData
          this.cart.paymentType = this.paymentType

          let customerData = await this.dataService.userData();


          if (null != customerData) {
            this.cart.customer = customerData
          }

          await this.firestoreService.updateOrder(this.cart.uid, this.cart)


          if (0 == this.paymentType) {

            this.paySecondStep(commerceOrder)

          }else if (1 == this.paymentType) {

            this.cart.updated = Date.now()
            this.cart.status = 9

            this.dataService.requestManualPayment(this.cart).then ((value: any) => {

              this.loading = false

              swal.fire({
                title: 'Aviso',
                html: this.TRANSFER_PAY_TEXT
              }).then((result) => {
                this.backAction();
              })

            }).catch(err => {
              this.loading = false

              this.showAlert('Aviso','Ha ocurrido un error')

            })

            //await this.firestoreService.updateOrder(this.cart.uid, this.cart)

          } else {

            this.cart.updated = Date.now()
            this.cart.status = 9

            this.dataService.requestManualPayment(this.cart).then ((value: any) => {

              this.loading = false

              swal.fire({
                title: 'Aviso',
                html: this.PAY_TEXT
              }).then((result) => {
                this.backAction();
              })


            }).catch(err => {

              this.loading = false


              this.showAlert('Aviso','Ha ocurrido un error')

            })

          }

        } catch (error) {
          this.loading = false

          this.showAlert('Aviso','Ha ocurrido un error.')

        }

      }

    }

    private paySecondStep (commerceOrder: string) {


      var jsonData = {'data': {
        "subject": "Pago de orden de compra " + commerceOrder,
        "currency": "CLP",
        "amount": this.totalNum(),
        "email": this.user.email || "",
        "commerceOrder": commerceOrder
      }}

      this.dataService.requestPayment(jsonData).then ((value: any) => {
        console.log(value);

        const data = JSON.parse(JSON.stringify(value))

        let token = data.token;

        if (null != token) {

          const flowUrl = data.url.concat("?token=").concat(data.token)

          console.log(flowUrl);

        this.popUp = window.open(
            flowUrl,
            "Secure Payment");

        }else {
          this.loading = false

            this.showAlert('Aviso', data.message)

        }

      })




/*


      //const url = "http://localhost:5001/app2020-cb4eb/us-central1/paymentCreate";
      const url = "https://us-central1-app2020-cb4eb.cloudfunctions.net/paymentCreate";

      const accumulator = "";
      const currentValue = "";

      //console.log(sign);
      console.log(url);

      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', "application/json");
      headers = headers.append('Accept', 'application/json');
      headers = headers.append('Access-Control-Allow-Credentials', 'true');
      headers = headers.append('Access-Control-Allow-Origin', "*");
      headers = headers.append("Access-Control-Allow-Headers", "X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method,Access-Control-Request-Headers, Authorization");
      headers = headers.append("Access-Control-Allow-Methods", "HEAD, GET, POST, PUT, PATCH, DELETE, OPTIONS");

      var params = new HttpParams()

      var jsonData = {
        "subject": "Pago de prueba desde web",
        "currency": "CLP",
        "amount": this.totalNum(),
        "email": this.user.email || "",
        "commerceOrder": commerceOrder
      }

      var data = JSON.parse(JSON.stringify(jsonData))

      this.http.post(url, { headers, data }).toPromise().then ((value: any) => {
        console.log(value);

        const data = JSON.parse(JSON.stringify(value))

        let token = data.token;

        if (null != token) {

          const flowUrl = data.url.concat("?token=").concat(data.token)

          console.log(flowUrl);

        this.popUp = window.open(
            flowUrl,
            "Secure Payment");




        }else {

            this.showAlert('Aviso', data.message)

        }

      })


*/

    }

    async checkPhone () {

      console.log("this.user.phone ", this.phoneNumber)
      try {

      this.hasPhoneNumber = phoneUtil.isValidNumberForRegion(phoneUtil.parse(this.phoneNumber, 'CL'), 'CL');

      }catch(err) {
        this.hasPhoneNumber = false;
      }
/*

*/
      console.log("this.user.phone hasPhoneNumber", this.hasPhoneNumber)

    }

    phoneNumberValue() {

      return this.phoneNumber || "";
    }

    phoneNumberField(event: any) {
      this.phoneNumber = event.target.value
      this.checkPhone ()
    }

    //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    private calcCrow(lat1: number, lon1: number, lat2: number, lon2: number)
    {
      var R = 6371; // km
      var dLat = this.toRad(lat2-lat1);
      var dLon = this.toRad(lon2-lon1);
      var lat1 = this.toRad(lat1);
      var lat2 = this.toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return d;
    }

    // Converts numeric degrees to radians
    private toRad(Value: number)
    {
        return Value * Math.PI / 180;
    }

  }


