import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { BaseComponent } from "../base.component";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { FirestoreService } from "../services/firestore.service";
import { MessagingService } from "../services/messaging.service";

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html'
  })

  export class ProductsComponent extends BaseComponent {

    public product: any = null;

    public categories: Array<any> = [];

    public count = 0
    public countInCart = 0

    public productVariant: any = null
    public productsVariants: Array<any> = [];

    public hasVariant = false


    constructor(
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef,
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService,
      private route: ActivatedRoute) {

      super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);

      console.log(this.route);

      this.route.params.subscribe(async params => {
          this.uuid = params['uuid'];

          console.log(this.uuid);

          this.product = await this.dataService.productByUID(this.uuid);


          //console.log("product:", this.product);
          console.log("product:", this.product);

          if (this.product) {

            this.hasVariant = (this.product.variants ?? []).length > 0


            await this.firestoreService.updateProduct(this.uuid, {"amount_views": (this.product.amount_views || 0) + 1});

            if (true == this.hasVariant) {

              console.log("hasVariant:", this.hasVariant);

              this.productsVariants = await this.dataService.productsByCategory(this.product.variants, false);

              //this.productsVariants = await this.dataService.productsByUID(this.product.variants, true, false);
              console.log("productsVariants:", this.productsVariants);

            }

          }


      });

    }

    async loadData () {

      super.loadData()

      if (this.product) {

        this.estimateCount ()

      }


    }

    estimateCount () {

      if (this.productVariant) {
        this.countInCart = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === this.productVariant.uid) ? ++pre : pre, 0)

      }else if (this.product) {
        this.countInCart = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === this.product.uid) ? ++pre : pre, 0)

      }

    }

    public async addToCart() {

      if (!this.authService.isLoggedIn()) {
        this.loginWithGoogle()

      }else {
        if (this.count > 0) {

          if (this.productVariant) {

            const price = this.priceNumberForProduct(this.productVariant);

            await this.dataService.addToCart(this.productVariant.uid, this.count, price, {});
            this.countInCart = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === this.productVariant.uid) ? ++pre : pre, 0)

          }else  {
            const price = this.priceNumberForProduct(this.product);

            await this.dataService.addToCart(this.uuid, this.count, price, {});
            this.countInCart = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === this.product.uid) ? ++pre : pre, 0)

          }

        }
      }



    }

    price (): string {
      return this.priceForProduct(this.productVariant ? this.productVariant : this.product)
    }

    productDescription () {
      return this.productVariant ?  this.productVariant.name : this.product.description
    }

    productImg () {
      return this.productVariant ?  this.productVariant.imgs[0] : this.product.imgs[0]
    }

    amountProductInCart (product: any): string {

      //console.log("amountProductInCart");

    //  const count = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === product.uid) ? ++pre : pre, 0)

      return ""// + count

    }

    public async add() {

      if (this.productVariant) {
        if (this.count + this.countInCart + 1 > this.productVariant.stock) {
          this.showAlert('Aviso', "Mayor que stock disponible")
        }else {
          this.count += 1
        }
      }else {
        if (this.count + this.countInCart + 1 > this.product.stock) {
          this.showAlert('Aviso', "Mayor que stock disponible")
        }else {
          this.count += 1
        }
      }

      //await this.dataService.addToCart(this.uuid, this.count)

    }

    public async substract() {
      if (this.count > 0) {
        this.count -= 1
      }
     // await this.dataService.addToCart(this.uuid, this.count)


    }

    selectedVariant () {
      return this.hasVariant ? null != this.productVariant : false
    }

    productName () {
      return this.productVariant ? this.productVariant.name : "Seleccionar"
    }

    selectProductVariant (product: any) {

      this.productVariant = product

    }

    productHasVariant() {
      return this.hasVariant
    }



  }
