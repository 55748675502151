import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgwWowModule } from 'ngx-wow';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

import { HomeModule } from './home/home.module';
import { ProductsModule } from './products/products.module';
import { PromotionsModule } from './promotions/promotions.module';
import { CategoriesModule } from './categories/categories.module';
import { StoresModule } from './stores/stores.module';
import { CartModule } from './cart/cart.module';
import { HistoryModule } from './history/history.module';
import { AddressesModule } from './addresses/addresses.module';

import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FirestoreService } from './services/firestore.service';
import { DataService } from './services/data.service';
import { SharedModule } from './sharedModule.module';
import { BaseModule } from './base.module';
import { MessagingService } from './services/messaging.service';
import { AsyncPipe } from '@angular/common';
import { NotifierModule } from 'angular-notifier';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { PhoneMaskDirective } from './cart/phone-mask.directive';

@NgModule({
  declarations: [
    AppComponent, PhoneMaskDirective
  ],
  exports: [
    PhoneMaskDirective
  ],
  imports: [
    GooglePlaceModule,
    NotifierModule,
    BrowserModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    BaseModule,
    SharedModule,
    NgwWowModule
  ],
  providers: [MessagingService, HttpClientModule, DataService, AsyncPipe, ScreenTrackingService, UserTrackingService],
  bootstrap: [AppComponent]
})
export class AppModule { }


/*

curl -X POST \
  https://fcm.googleapis.com/fcm/send \
  -H 'Authorization: key=AAAAMUYWsQI:APA91bH2XSfX3qWFEwddF31JB9BwFHO_KJgqxKWlNZF_OlZemqX77DBiyvP5zzwmLtQuX8-2OfCNHqwBF5JCcZhnfjvVV-KbUkRhrbkyueF_usBQaGZD5rrvcXz8UrjV2eynumHScZXl' \
  -H 'Content-Type: application/json' \
  -d '{
 "notification": {
  "title": "Hey there",
  "body": "Subscribe to mighty ghost hack youtube channel"
 },
"data": {
  "title": "Hey there",
  "body": "Subscribe to mighty ghost hack youtube channel"
 },
 "to" : "fU5iaaCHSqmvmTncXlS-VC:APA91bEPpdcBDZX1SoeXKlLkuCb-MLZauAhdxzlM80ZsHlKNV57uc8T02R4sEcAFwtwFwsnK6Hx0ieR0cPx7fSsJkhbYm4TsuJbYlPYpLcABK01aNbeMJShC8asMgZHReeQENphvIWvQ"
}'


*/