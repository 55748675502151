import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { FirestoreService } from '../services/firestore.service';
import { MessagingService } from '../services/messaging.service';
import { NotifierService } from 'angular-notifier';
import { Console } from 'console';

require('typeface-oswald');

declare var $: any;

@Component({
    selector: 'app-workers',
    templateUrl: './home.component.html'
  })

  export class HomeComponent extends BaseComponent {
    [x: string]: any;

    public families: Array<any> = [];
    public categories: Array<any> = [];
    public products: Array<any> = [];
    public productsFeatured: Array<any> = [];
    public productsRecommended: Array<any> = [];

    public productsMoreViews: Array<any> = [];
    public promotions: Array<any> = [];

    public modificators: Array<any> = [];
    public modificatorsProducts: Array<any> = [];

    public viewAll = false;

    public count = 0;
    public countInCart = 0;

    public selectedProduct: any | null = null;
    public selectedFamily: any | null = null;
    public selectedCategory: any | null = null;

    public selectedModificatorProducts: Array<any> = [];

    public selectedModificatorProductsData: any = {};

    constructor(
      private renderer: Renderer2,
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd: ChangeDetectorRef,
      public element: ElementRef,
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService) {

      super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);

      this.renderer?.listen('window', 'click', (e: Event) => { // your code here})

          console.log('home click:', e);

        });

    }

    async ngOnInit() {

      super.ngOnInit();
      console.log('user.displayName: ', this.user?.displayName ?? '');

      this.dataService.dataLoaded.subscribe (async status => {

        console.log('status');

        if (status) {

          this.products = await this.dataService.currentProducts();

          this.productsFeatured = this.products.filter(
            function(product) {
              return product.featured || false;
            }
          );

          this.productsMoreViews = await this.dataService.currentProductsMoreViews();

          this.categories = await this.dataService.currentCategories();

          this.families = await this.dataService.currentFamilies();

          this.modificators = await this.dataService.currentModificators();

          this.modificatorsProducts = await this.dataService.currentModificatorsProducts();

          // this.promotions = await this.dataService.currentPromotions();

          console.log('home families:', this.families);

          for (let index = 0; index < this.families.length; index++) {
            const family = this.families[index];
            family.categoriesData =  this.categoriesForFamily(family);
          }

/*
              this.firestoreService.getCategories().toPromise().then((docs) => {

            this.categories = docs.docs.map(function(doc) {

              var data: any = doc.data();
              data.uid = doc.id;
              data.link = "/category/" + doc.id;
              return data;

            })
*/


            // this.cd.detectChanges();

          this.loading = true;





        }



      });

    }

    categoriesForFamily(family: any): Array<any> {
      const uids: Array<any> = family.categories ?? [];
      console.log('uids ', uids);

      const returnValue = this.categories.filter(e => {
        return uids.includes(e.uid);
       }) || [];


      return returnValue;

    }

    stockForProduct(product: any) {

      return 0;
      return product.stock || 0;
    }

    selectProduct(event: any, product: any) {

      this.selectedProduct = product;
      const uids: Array<any> = product.productsRecommended ?? [];

      this.productsRecommended = this.products.filter(e => {
        return uids.includes(e.uid);
       }) || [];

      console.log('selectProduct ', product);
      this.estimateCount ();

      this.goUp();
    }

    selectCategory(event: any, category: any) {

      this.unSelectCategory(event, category);
      this.unSelectFamily(event, category);

      this.selectedCategory = category;

      console.log('selectCategory ', category);
      this.estimateCount ();


    }

    selectFamily(event: any, family: any) {

      this.unSelectCategory(event, family);
      this.unSelectFamily(event, family);

      this.selectedFamily = family;

      console.log('selectFamily ', family);
      this.estimateCount ();


    }

    unSelectCategory(event: any, category: any) {

      this.selectedCategory = null;

    }

    unSelectFamily(event: any, family: any) {

      this.selectedFamily = null;

    }

    unselectAll(event: any, family: any) {

      this.unSelectCategory(event, family);
      this.unSelectFamily(event, family);
      this.estimateCount();
    }

    unSelectProduct(event: any, product: any) {
      this.goUp();
      this.selectedProduct = null;
      this.count = 0;

      this.selectedModificatorProducts = [];
      this.selectedModificatorProductsData = {};
      this.estimateCount ();


    }

    nameForProduct() {

      return this.selectedProduct?.name || '';

    }

    toggleViewAll() {
      this.viewAll = !this.viewAll;
    }
/*
   priceForProduct (currentProduct: any): string {

    const num = this.priceNumberForProduct(currentProduct);

    const total = (this.selectedModificatorProducts || []).reduce((pre: number, cur: any) => pre + cur.price, 0);


    product.price_str = formatCurrency(product.price, 'cl', '$', 'CLP');




      return currentProduct.price_str;

      if (currentProduct.price_discount ){
        return currentProduct.price_str;
      }
      return currentProduct.price_discount < currentProduct.price ? currentProduct.price_discount_str : currentProduct.price_str;
    }
*/

    price(): string {
      return this.selectedProduct ? this.priceForProduct(this.selectedProduct) : '';
    }

    hasModificators() {
      return (this.selectedProduct?.modificators || []).length > 0;
    }

    priceNumForModificatorProduct(modificator: any, product: any): number {


      const returnValue: Array<any> = (modificator.products || []).filter(function(e: any) {
        return e.uid === product.uid;
       }) || [];



      if (returnValue.length > 0) {
        return (returnValue[0].price || 0);
      }

      return 0;

    }

    priceForModificatorProduct(modificator: any, product: any): string {

      return '$' + this.priceNumForModificatorProduct(modificator, product);

    }

    descriptionForProduct() {
      return this.selectedProduct?.description || '';
    }

    modificatorsForProduct(product: any): Array<any> {
      const uids: Array<any> = (product.modificators ?? []).map((e: { uid: any; }) => {
        return e.uid;
       }) || [];
      const returnValue = this.modificators.filter(e => {
        return uids.includes(e.uid);
       }) || [];

      return returnValue;
    }

    modificatorsProductForModificator(modificator: any): Array<any> {
      const uids: Array<any> = (modificator.products ?? []).map((e: { uid: any; }) => {
        return e.uid;
       }) || [];


      const returnValue = this.modificatorsProducts.filter(e => {
        return uids.includes(e.uid);
       }) || [];

      return returnValue;
    }

    isModificatorProductSelected(modificatorProduct: any) {




      return this.selectedModificatorProducts.includes(modificatorProduct);
    }

/*
    isModificatorProductForProductSelected(modificator: any, modificatorProduct: any, product: any) {

      const keyProduct = String(product.uid);
      const keyModificator = String(modificator.uid);
      const keyModificatorProduct = String(modificatorProduct.uid);

      const mapProducts =  this.selectedModificatorProductsData.get([keyProduct]) || {};
      const mapModificators =  mapProducts.get([keyModificator]) || {};
      const mapModificatorProducts =  mapModificators.get([keyModificatorProduct]) || {};


      return this.selectedModificatorProducts.includes(modificator);
    }

    selectModificatorProductForProduct (modificator: any, modificatorProduct: any, product: any) {


      const keyProduct = String(product.uid);
      const keyModificator = String(modificator.uid);
      const keyModificatorProduct = String(modificatorProduct.uid);

      const mapProducts =  this.selectedModificatorProductsData.get([keyProduct]) || {};
      const mapModificators =  mapProducts.get([keyModificator]) || {};
      const mapModificatorProducts =  mapModificators.get([keyModificatorProduct]) || {};


      if (this.selectedModificatorProducts.includes(modificator)) {
        this.selectedModificatorProducts.splice(modificator);
      }else {
        this.selectedModificatorProducts.push(modificator);
      }

    }
*/

    selectModificatorProduct (modificator: any, modificatorProduct: any) {

      modificatorProduct.price = this.priceNumForModificatorProduct(modificator, modificatorProduct);


      if (this.selectedModificatorProducts.includes(modificatorProduct)) {
        this.selectedModificatorProducts.splice(modificatorProduct);
      }else {
        this.selectedModificatorProducts.push(modificatorProduct);
      }

    }


   imgsForProductLength() {
      return this.selectedProduct?.img?.length || 0;
    }

    imgsForProduct() {
      return this.selectedProduct?.imgs || [];
    }

    imgForProduct() {
      return this.selectedProduct?.imgs[0] || '';
    }

    public async add() {


      if (this.count + this.countInCart + 1 > (this.selectedProduct.stock || 100)) {
        this.showAlert('Aviso', 'Mayor que stock disponible');
      }else {
        this.count += 1;
      }


      // await this.dataService.addToCart(this.uuid, this.count)

    }

    // tslint:disable-next-line:typedef
    public async substract() {
      if (this.count > 0) {
        this.count -= 1;
      }
     // await this.dataService.addToCart(this.uuid, this.count)


    }

    filterProductsByCatetory() {
      const uids: Array<any> = this.selectedCategory.products ?? [];

      const returnValue = this.products.filter(e => {
        return uids.includes(e.uid);
       }) || [];

      return returnValue;
    }

    filterProductsByFamily() {

      const uidsCategories: Array<any> = this.selectedFamily.categories ?? [];

      var returnValue: Array<any> = [];


      uidsCategories.forEach(uid => {

        const category = this.categories.find(function(category) {
          return category.uid === uid;
        });


        if (category) {
          const uids: Array<any> = category.products ?? [];


          const filteredProducts: Array<any> = (this.products.filter(e => {
            return uids.includes(e.uid);
           }) || []);


           returnValue.push(...filteredProducts);


        }


      });

      return returnValue;
    }

    productsToShow() {
      if (this.selectedCategory) {
        return this.filterProductsByCatetory();
      }else if (this.selectedFamily) {
        return this.filterProductsByFamily();
      }else {
        return this.viewAll ? this.products : this.productsFeatured;
      }
    }



viewMoreButtonTitle() {
  return this.viewAll ? 'Ver recomendados' : 'Ver todos';

}


    // tslint:disable-next-line:typedef
    estimateCount() {

      if (this.selectedProduct) {
        // tslint:disable-next-line:max-line-length
        this.countInCart = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === this.selectedProduct.uid) ? ++pre : pre, 0);

      }

    }



    public async addToCart() {

      console.log('addToCart ');

      if (!this.authService.isLoggedIn()) {
        this.loginWithGoogle();

      }else {
        if (this.count > 0) {

            const price = this.priceNumberForProduct(this.selectedProduct);

            const keyProduct = String(this.selectedProduct.uid);


            const mapModificators =  {[keyProduct]:  this.selectedModificatorProducts.map(e => {
               return {'uid': e.uid,
                       'price': Number(e.price)};
              })};


            console.log('addToCart ', mapModificators);

            await this.dataService.addToCart(this.selectedProduct.uid,
               this.count,
               price,
               mapModificators);

            this.countInCart = (this.cart.products || [])
            .reduce((pre: number, cur: string) => (cur === this.selectedProduct.uid) ? ++pre : pre, 0);



        }
      }



    }

  }
