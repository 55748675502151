import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Console } from 'console';
import { combineLatest, Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  constructor(
    private storage: AngularFireStorage,
    private firestore: AngularFirestore
  ) {}

  public referenceCloudStorage(name: string) {
    return this.storage.ref(name);
  }

  public uploadImage(ref: any, file: any) {
    return ref.put(file);
  }
/*
  public uploadImages(name: string, data: any) {
    return this.storage.upload(name, data);
  }
*/
  public createOrder(data: any) {
    return this.firestore.collection('Orders').add(data);
  }

  public getOrder(documentId: string) {
    return this.firestore.collection('Orders').doc(documentId);
  }

  public getOrders() {
    return this.firestore.collection('Orders').snapshotChanges();
  }

  public updateOrder(documentId: string, data: any) {
    return this.firestore.collection('Orders').doc(documentId).set(data);
  }

  public getCategories() {
    return this.firestore.collection('Categories', ref => ref.where( 'enabled' , '==' , true).orderBy('order', 'desc')).get();
  }

  public getModificators() {
    return this.firestore.collection('Modificators', ref => ref.orderBy('updated', 'desc')).get();
  }

  public getModificatorsProducts() {
    return this.firestore.collection('ModificatorsProducts', ref => ref.orderBy('updated', 'desc')).get();
  }

  public getFamilies() {
    return this.firestore.collection('Families', ref => ref.where( 'enabled' , '==' , true).orderBy('order', 'desc')).get();
  }

  public getPromotions() {
    return this.firestore.collection('Promotions').get();
  }

  public getProducts() {

    return this.firestore.collection('Products', ref => ref.where( 'enabled' , '==' , true).where( 'active' , '==' , true)).get();

  }

  public getProductsMoreViews() {

    return this.firestore.collection('Products' , ref => ref.where( 'enabled' , '==' , true).where( 'active' , '==' , true).orderBy('amount_views', 'desc').limit(12)).get()

  }

  public updateProduct(documentId: string, data: any) {

    return this.firestore.collection('Products').doc(documentId).update(data);

  }

  public getProductByUID(product: string) {

    return this.firestore.collection('Products').doc(product);

  }

  public getProductsByUID(products: [string], enabled: boolean = true, active: boolean = true) {

    return this.firestore.collection('Products', ref => ref.where(ref.id , 'in' , ['EieUx24UUXGbzO0bR9xC'])).get()

}

    //return this.firestore.collection('Products').get;



  public getCustomer(documentId: string) {
    return this.firestore.collection('Customers').doc(documentId);

    //return this.firestore.collection('Orders').doc(documentId).snapshotChanges();
  }

  public async updateCustomer(documentId: string, data: any) {

    const customer = await this.getCustomer(documentId).get().toPromise()

    if (customer.exists) {

      var storedData: any = (await customer.ref.get()).data()

      if (storedData.userPoints == null) {
        data.userPoints = 3000
      }

      return this.firestore.collection('Customers').doc(documentId).update(data);
    }


    data.userPoints = 3000

    return this.firestore.collection('Customers').doc(documentId).set(data);

  }

  public subscribToOrder(documentId: string) {
    return this.firestore.collection('Orders').doc(documentId).snapshotChanges();
  }

  public getStores() {

    return this.firestore.collection('Stores').get();

  }

}
