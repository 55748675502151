import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/environments/environment';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from  '@angular/common/http';
import { HmacSHA256 } from "crypto-js";
import { FirestoreService } from '../services/firestore.service';
import { DataService } from '../services/data.service';
import { BaseComponent } from '../base.component';
import { ActivatedRoute } from '@angular/router';
import { formatCurrency, registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/es-CL';
import { AuthService } from '../services/auth.service';
import { MessagingService } from '../services/messaging.service';
import { NotifierService } from 'angular-notifier';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ComponentRestrictions } from 'ngx-google-places-autocomplete/objects/options/componentRestrictions';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DomSanitizer } from '@angular/platform-browser';
import { timingSafeEqual } from 'crypto';
import {FormBuilder, ReactiveFormsModule, FormsModule, NgControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'AutocompleteComponent',
  templateUrl: './addresses.component.html'
  })
  
  export class AddressesComponent extends BaseComponent {

    public validDistricts: Array<any> = ["Macul", "Peñalolen", "Ñuñoa", "Providencia", "Las condes",  "La reina"];


    @ViewChild('places') places: GooglePlaceDirective | undefined;

    public newAddress: Address | null = null;
    public validAddress = false;

    public urlSafe?: any

    public name: string | null = null;
    public apartment: string | null = null;

    public addresses: Array<any> = [];

    constructor(
      private ref: ChangeDetectorRef,
      public sanitizer: DomSanitizer,
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef, 
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService,
      private route: ActivatedRoute) {
              
        super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);

      }

    async loadData () {

      super.loadData()
      
      this.addresses = this.user.addresses || []

      if (this.places) {
        
        this.places.options.componentRestrictions = new ComponentRestrictions({ country: "cl" });

        this.places.reset();

      }
      
      /*
      console.log("pastOrders ", this.pastOrders);
      this.orders.forEach(element => {

        console.log("followingOrders ", this.followingOrders);

        if (element.status === 2) {
          this.followingOrders.push(element)
        }else {
          this.pastOrders.push(element)
        }
      });
*/
      console.log("addresses ", this.addresses);

    }

    nameAddress(event: any) {
      this.name = event.target.value
    }

    apartmentAddress(event: any) {
      this.apartment = event.target.value
    }

    textAddress(address: any): string {

      return address.detail + ", Depto: " + address.apartment 


    }

    showAddressAlert () {

      this.showAlert("Aviso", "Lo sentimos pero todavía no llegamos a tu comuna.")

    }


    validateAddress (address: Address): boolean {

      console.log("validAddress")

      var returnValue = false

      for (var i = 0, len = address.address_components.length || 0; i < len; i++) {

        const element = address.address_components[i]

        if (element?.types.find(function (type) {
          return type.includes('administrative_area_');
        })) {

          const long_name = element.long_name.toUpperCase()
          const short_name = element.short_name.toUpperCase()

          let value = this.validDistricts.find(function (district) {

            const districtVal = district.toUpperCase()
            console.log("district", districtVal)

            return districtVal.includes(long_name) || districtVal.includes(short_name);
            
          })

          if (value) {
            returnValue = true
            break
          }


        }

      }
      console.log('returnValue', returnValue);

      return returnValue

    }

    public onChange(address: Address) {
/*
      if(address.photos && address.photos.length > 0){
          console.dir(address.photos[0].getUrl({maxHeight:500,maxWidth:500}));
      }
      */
      let x = this.getComponentByType(address,"street_number");

      address.address_components.forEach(element => {
        if (element.types.find(function (type) {
          return type.includes('administrative_area_');
        })) {
          console.log('address_components', element.short_name);

        }
      })

      console.log(address.geometry.location.lng());
      console.log(address.geometry.location.lat());
      console.log(address.geometry.location.toJSON().toString());
      console.log(address.geometry.viewport.getNorthEast());
      console.log(address.place_id);

      this.newAddress = address

      this.validAddress = this.validateAddress(address)

      if (!this.validAddress) {
        this.showAddressAlert()
      }

      console.log('this.newAddress', this.newAddress);

      const addressStr = address.geometry.location.lat() + "," + address.geometry.location.lng()

      var base64 = btoa(addressStr); // lazy "long,lat" formatting
      console.log("base64 ", base64);

      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed?pb=!1m7!1m2!1m1!1d2256.8774176856136!3m3!1m2!1s0!2z" + base64);

  }

  public getComponentByType(address: Address, type: string): AddressComponent | null {
      if(!type)
          return null;

      if (!address || !address.address_components || address.address_components.length == 0)
          return null;

      type = type.toLowerCase();

      for (let comp of address.address_components) {
          if(!comp.types || comp.types.length == 0)
              continue;

          if(comp.types.findIndex(x => x.toLowerCase() == type) > -1)
              return comp;
      }

      return null;
  }
  public changeConfig() {

    console.log('changeConfig');


  }

  public async save(event: any) {

    if (null === this.newAddress || null === this.name || null === this.apartment) {

      this.showAlert('Aviso', "Por favor agrega todos los datos.")

    }else {

      if (!this.validAddress) {
        this.showAddressAlert()

      }else {
        
      const dataAddress = {
        'name': this.name,
        'apartment': this.apartment,
        'address_components': this.newAddress?.address_components || [],
        'detail': this.newAddress?.formatted_address || "",
        'placeId': this.newAddress?.place_id || "",
        'lat': this.newAddress?.geometry.location.lat() || 0.0,
        'lng': this.newAddress?.geometry.location.lng() || 0.0
      }

      var userAddresses: Array<any> = this.user.addresses || []

      userAddresses.push(dataAddress)

      this.user.addresses = userAddresses;

      console.log('this.user.addresses', this.user);

      await this.firestoreService.updateCustomer(this.user.uid, this.user)
      this.dataService.updateCurrentUser(this.user, this.authService.currentUser)

      this.newAddress = null
      this.name = null
      this.apartment = null

    }

    }

  }


}
