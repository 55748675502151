import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {

currentMessage = new BehaviorSubject({});
currentToken = new BehaviorSubject("");

constructor(private angularFireMessaging: AngularFireMessaging) {

    this.angularFireMessaging.messages.subscribe(
        

        (_messaging) => {
            console.log('angularFireMessaging');

        }

    )

}

requestPermission() {
    console.log('requestPermission');

    this.angularFireMessaging.requestToken.subscribe((token) => {
        //console.log(token);
        if (token !== null)
            this.currentToken.next(token);
    }, (err) => {
        console.error('Unable to get permission to notify.', err);
    });

}

receiveMessage() {

    console.log('receiveMessage');

    this.angularFireMessaging.messages.subscribe((payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
    })

}

}