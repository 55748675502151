import { Provider, Injectable } from '@angular/core';
import { timer, BehaviorSubject, Observable, Subscription} from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirestoreService } from './firestore.service';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { formatCurrency, registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/es-CL';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpParams } from '@angular/common/http';
import { switchMap, takeUntil, catchError, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { AuthService } from './auth.service';
import firebase from 'firebase/app';
import { Map } from 'typescript';

const DEFAULT_TOKEN = 'Bg6YnI3YwsMPVBE90x0naECiRnzYmXCJ';
const imgUrl = 'https://firebasestorage.googleapis.com/v0/b/app2020-cb4eb.appspot.com/o/login.png?alt=media&token=9f8c887a-d4dc-4481-9237-f54e3612792f';
const commonUrl = 'https://us-central1-pascucci-f33c4.cloudfunctions.net/';
//const commonUrl = "http://localhost:5001/prod/us-central1/";

//const commonUrl = "http://localhost:5001/pascucci-f33c4/us-central1/";

@Injectable({
    providedIn: 'root'
  })
export class DataService {

    fcmToken: string | null = null;
    currentFBUser: firebase.User | null = null;

    private _documentCustomer: AngularFirestoreDocument<any> | null  = null;
    private _documentCart: AngularFirestoreDocument<any> | null  = null;

    private _currentUser = new BehaviorSubject<any>({displayName: "Guest", name: "Guest", img: imgUrl});

    private cartSubscription: Subscription | null  = null;
    private _currentCart = new BehaviorSubject<any>({products:[]});

    private _currentCustomer: Subscription | null  = null;

    private _dataLoaded = new BehaviorSubject<boolean>(false);
    private _checkTime = new BehaviorSubject<boolean>(false);

    private families: Array<any> | null = null;
    private categories: Array<any> | null = null;
    private products: Array<any> | null = null;
    private productsMoreViews: Array<any> | null = null;
    private stores: Array<any> | null = null;
    private promotions: Array<any> | null = null;
    private modificators: Array<any> | null = null;
    private modificatorsProducts: Array<any> | null = null;



    timer: any;

    constructor(private http: HttpClient,
        private firestoreService: FirestoreService) {

        console.log("Instance DataService");
        console.log(Date.now().toString())

        registerLocaleData(locale, 'cl');

        this._dataLoaded.next(true);

        this.runTimer()

    }

    private async readCart (order: string | null = null) {

     //   console.log("readCart:", order);

        this.cartSubscription?.unsubscribe()
        this._documentCart = null

        if (null !== order) {

            this._documentCart = this.firestoreService.getOrder(order);

     //       console.log("_documentCart 1");
/*
            await this._documentCart.ref.get().then((doc) => {
                if (doc.exists) {


                    var data = doc.data()
                    data.uid = order
                    console.log("Cart data:", data);

                    this._currentCart.next(data)

                } else {
                    console.log("No such document!");

                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
            console.log("_documentCart 3");
*/
            this.cartSubscription = this._documentCart.valueChanges() .pipe(
                distinctUntilChanged() // or try this, ignores equal data
               ).subscribe((data: any) => {

                const prevCartTime = this._currentCart.getValue().updated || 0;
                const cartTime = data.updated || 0;

                console.log("prevCartTime ", prevCartTime);
                console.log("cartTime ", cartTime);


                if (prevCartTime == cartTime) {
                   return
                }

              //      console.log("cartSubscription:", data);

                    var cart = data
                    cart.uid = order

                    this._currentCart.next(data)




            });

        }else {
            this._currentCart.next({})

        }

        this._dataLoaded.next(true);

    }

    private async readCustomer (uid: string) {

        console.log("readCustomer:", uid);


/*
        this._documentCustomer.ref.get().then((doc) => {

            if (doc.exists) {

                console.log("Customer data:", doc.data());

                const orders = doc.data().orders;

                if (orders.length > 0) {

                    const order = orders[0];

                    console.log("order ", order);

                    if (null == this._documentCart) {
                        this.readCart(order);
                    }

                }else {

                    this._dataLoaded.next(true);

                }

            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
*/

        this._documentCustomer = this.firestoreService.getCustomer(uid);

        this._currentCustomer?.unsubscribe()



        this._currentCustomer = this._documentCustomer.valueChanges().pipe(
            distinctUntilChanged() // or try this, ignores equal data
           ).subscribe(async (data: any) => {


            const prevUserTime = this._currentUser.getValue().updated || 0;
            const userTime = data.updated || 0;

            console.log("prevUserTime ", prevUserTime);
            console.log("userTime ", userTime);


            if (prevUserTime == userTime) {
                return
            }

            console.log("_currentCustomer ", data);

            const orders = data?.orders || [];

            if (orders?.length ?? 0 > 0) {

                const order = orders[orders.length - 1];

                if (order.status !== 1) {

                   const url = commonUrl + "cartForUser?uid=" + uid;

                    var headers = await this.commonHeaders(false)

                    const costumer: any = await this.http.get(url, { headers }).toPromise()

                    console.log("cartForUser ", costumer);

                    const orders = costumer.orders;

                    if (orders.length > 0) {

                        const order = orders[orders.length - 1];

                        console.log("order ", order);

                    //  if (null == this._documentCart) {
                            this.readCart(order);
                    //  }

                    }else {

                        this._dataLoaded.next(true);

                    }

                }else {
                    this.readCart(order);
                }

            }else {

                const url = commonUrl + "cartForUser?uid=" + uid;

                var headers = await this.commonHeaders(false)

                const costumer: any = await this.http.get(url, { headers }).toPromise()

                console.log("cartForUser ", costumer);

                const orders = costumer.orders;

                if (orders.length > 0) {

                    const order = orders[orders.length - 1];

                    //console.log("order ", order);

                //  if (null == this._documentCart) {
                        this.readCart(order);
                //  }

                }else {

                    this._dataLoaded.next(true);

                }

            }
            this._currentUser.next(data)


        });

    }

    runTimer () {

     this.timer = setInterval(() => {

            this._checkTime.next(true)

          }, 6000);

    }

    logoutUser () {

        this.updateCurrentUser({displayName: "Guest", img: imgUrl}, null)
        this.readCart(null)

    }

    updateCurrentUser (user: any, fbUser: firebase.User | null) {

        console.log('updateCurrentUser', user);
        console.log('firebase', fbUser);

        this.currentFBUser = fbUser

        this._currentUser.next(user)

        if (user.uid != null) {

            this.readCustomer(user.uid)

        }else {

            this._currentCustomer?.unsubscribe()
            this._dataLoaded.next(true);

        }

    }

    get currentUser(): Observable<any> | null  {
        return this._currentUser;
    }

    get currentCart(): BehaviorSubject<any> | null {
/*
       console.log('currentCart');
        if (this._currentCart === null) {
            console.log('null currentCart');
        }
*/
        return this._currentCart;

    }


    get dataLoaded(): BehaviorSubject<boolean> {
        return this._dataLoaded;
    }


    get checkTime(): BehaviorSubject<boolean> {
        return this._checkTime;
    }

    async currentStore () {

        var stores = await this.currentStores()

        if (null != this.currentStores) {

            //console.log("currentStores", this.currentStores);

            return stores[0] || null

        }else {
            return null
        }
    }

    async categoryByUID (uid: String) {

        await this.currentCategories();

        if (null != this.categories) {

            //console.log("currentCategories", this.categories);

            return this.categories!.find(function(category) {
                return category.uid == uid
              });

        }else {
            return null
        }

    }

    async promotionByUID (uid: string) {

        var promotions = await this.currentPromotions()

        var promotion = promotions.find(element => element.uid == uid)


        return promotion


        //var product: any = await this.firestoreService.getProductByUID(uid).valueChanges()

/*
        const url = commonUrl + "products?uid=" + uid;


        var product: any = await this.http.get(url, { headers }).toPromise()
*/





/*
        await this.currentProducts()

        if (null != this.products) {

            console.log("currentProducts", this.products);

            return this.products!.find(function(product) {
                return product.uid == uid
              });

        }else {
            return null
        }

        */
    }

    async productsByUID(products: [string], enabled: boolean, active: boolean) {

        console.log("productsByUID ", products);
        console.log("enabled ", enabled);
        console.log("active ", active);

        return (await this.firestoreService.getProductsByUID(products, enabled, active).toPromise()).docs.map(function(productData) {

            var product:any = productData.data();

            console.log("productData ", productData);

            product.uid = productData.id

            product.price_str = formatCurrency(product.price, 'cl', '$', 'CLP')
            product.price_discount_str = formatCurrency(product.price_discount, "cl", '$', 'CLP')

            return product

          })

    }

    async productByUID (uid: string) {

        //console.log("getProductByUID ", uid);

        return this.firestoreService.getProductByUID(uid).ref.get().then (function (doc) {
            if (doc.exists) {

                var product:any = doc.data();

              //console.log("product ", product);

              product.uid = uid

              product.price_str = formatCurrency(product.price, 'cl', '$', 'CLP')
              product.price_discount_str = formatCurrency(product.price_discount, "cl", '$', 'CLP')

              return product

            } else {
              console.log("There is no document!");
            }
          }).catch(function (error) {
            console.log("There was an error getting your document:", error);
          });




        //var product: any = await this.firestoreService.getProductByUID(uid).valueChanges()

/*
        const url = commonUrl + "products?uid=" + uid;


        var product: any = await this.http.get(url, { headers }).toPromise()
*/





/*
        await this.currentProducts()

        if (null != this.products) {

            console.log("currentProducts", this.products);

            return this.products!.find(function(product) {
                return product.uid == uid
              });

        }else {
            return null
        }

        */
    }

    async orderIdForUser  (uid: string) {

        const url = commonUrl + "orderIdForUser?uid=" + uid;
        var headers = await this.commonHeaders(false)

        var order: any = await this.http.get(url, { headers }).toPromise()

        return order

    }

    async indexForOrder  (uid: string) {

        const url = commonUrl + "indexForOrder"
        var headers = await this.commonHeaders(false)

        var order: any = await this.http.get(url, { headers }).toPromise()

        return order

    }

    async ordersHistory (uid: string) {

        console.log("uid ", uid);

        const url = commonUrl + "ordersForUser?uid=" + uid;

        var headers = await this.commonHeaders(false)

        var orders: any = await this.http.get(url, { headers }).toPromise()

        var followingOrders = orders.ordersPending || [];
        var pastOrders = orders.ordersHistoric || [];

        followingOrders.forEach(function(item: any) {
            var data = item
            data.amount_str = formatCurrency(data.amount || 0, 'cl', '$', 'CLP')

            data.link = "/history/" + data.uid;
            return data
         })

         pastOrders.forEach(function(item: any) {
            var data = item
            data.amount_str = formatCurrency(data.amount || 0, 'cl', '$', 'CLP')

            data.link = "/history/" + data.uid;
            return data
         })

        return orders

    }

    async requestManualPayment (order: any) {

        const url = commonUrl + "assignManualPaymentForOrder";

        var headers = await this.commonHeaders(false)

        var data = JSON.parse(JSON.stringify(order))

        return this.http.post(url, data, { headers }).toPromise()

    }

    async requestPayment (order: any) {

        const url = commonUrl + "paymentCreate";

        var headers = await this.commonHeaders(false)

        var data = JSON.parse(JSON.stringify(order))


        return this.http.post(url, data, { headers }).toPromise()

    }

    async productsByCategory (productsCategory: [string], filter: boolean = true) {

        console.log("join ", JSON.stringify(productsCategory));

        const url = commonUrl + "products?uids=" + JSON.stringify(productsCategory);

        var headers = await this.commonHeaders(true);
/*
        const httpOptions = {
            headers: headers
          };
*/
/*
          const httpOptions = {
            headers: new HttpHeaders({
             'Content-Type':  'application/json',
             'Authorization': 'my-auth-token'
            })
          };


          const headers = new HttpHeaders()
            .set("X-CustomHeader", "custom header value");


            this.http.get(url,  {headers}).subscribe((response) => {
                console.log(response);

            });
*/
        var products: any = await this.http.get(url,  {headers}).toPromise();

        products = products.map(function(item: any) {
            var data: any  = item;
            data.price_str = formatCurrency(data.price, 'cl', '$', 'CLP')
            data.price_discount_str = formatCurrency(data.price_discount, "cl", '$', 'CLP');
            data.order = data.order || 10000;
            data.link = "/product/" + data.uid;
            return data;
         }).filter(function(product: any) {
            return filter ? product.enabled === true && product.active === true && product.availableEcommerce === true : true;
         });

         products?.sort((a: any, b: any) => (a?.order > b?.order) ? 1 : ((b?.order > a?.order) ? -1 : 0));

    //    console.log("productsByCategory ", products);

        return products;

    }

    async currentCategories ()  {

        if (null === this.categories) {

       //     console.log("no null");

           var  data = await this.firestoreService.getCategories().toPromise();

           this.categories = data.docs.map(function(doc) {

            var data: any = doc.data();
            data.uid = doc.id;
            data.link = "/category/" + doc.id;
            return data;

          })

          this.categories.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))


        }else {
       //     console.log("null");

        }

        return this.categories;

    }


    async currentModificators ()  {

      if (null === this.modificators) {


         var  data = await this.firestoreService.getModificators().toPromise();

         this.modificators = data.docs.map(function(doc) {

          var data: any = doc.data();
          data.uid = doc.id;
          data.link = "/modificator/" + doc.id;
          return data;

        })

       // this.modificators.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))


      }

      return this.modificators;

  }

  async currentModificatorsProducts ()  {

    if (null === this.modificatorsProducts) {


       var  data = await this.firestoreService.getModificatorsProducts().toPromise();

       this.modificatorsProducts = data.docs.map(function(doc) {

        var data: any = doc.data();
        data.uid = doc.id;
        data.link = "/modificatorProduct/" + doc.id;
        return data;

      })

     // this.modificators.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))


    }

    return this.modificatorsProducts;

}



    async  currentFamilies()  {

      if (null === this.families) {

     //     console.log("no null");

         const data = await this.firestoreService.getFamilies().toPromise();

         this.families = data.docs.map(function(doc) {

          const family: any = doc.data();
          family.uid = doc.id;
          family.link = '/family/' + doc.id;

          console.log("family order " + family.order);

          return family;

        });

         this.families.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))



      }else {
     //     console.log("null");

      }

      return this.families;

  }

    async currentStores ()  {

        if (null === this.stores) {


           var  data = await this.firestoreService.getStores().toPromise();

           this.stores = data.docs.map(function(doc) {

            var data: any = doc.data();
            data.uid = doc.id;
            data.link = "/store/" + doc.id;
            return data;

          })

        }

        return this.stores

    }

    async currentPromotions ()  {

        if (null === this.promotions) {

            console.log("no null");

           var  data = await this.firestoreService.getPromotions().toPromise();

           this.promotions = data.docs.map(function(doc) {

            var data: any = doc.data();
            data.uid = doc.id;
            data.link = "/promotion/" + doc.id;
            data.price_str = formatCurrency(data.price, 'cl', '$', 'CLP')
            return data;

          })

        }else {
            console.log("null");

        }

        return this.promotions

    }



    async currentProductsMoreViews()  {

        if (null === this.productsMoreViews) {

            console.log("no null");

           var  data = await this.firestoreService.getProductsMoreViews().toPromise();

           this.productsMoreViews = data.docs.map(function(doc) {

            var data: any = doc.data();

            data.price_str = formatCurrency(data.price, 'cl', '$', 'CLP')
            data.price_discount_str = formatCurrency(data.price_discount, "cl", '$', 'CLP')

            data.uid = doc.id;
            data.link = "/product/" + doc.id;
            return data;

          })


        }else {
            console.log("null");

        }

        return this.productsMoreViews

    }

    async currentProducts ()  {

        if (null === this.products) {

            console.log("no null");

           var  data = await this.firestoreService.getProducts().toPromise();

           this.products = data.docs.map(function(doc: any) {

            var data: any = doc.data();

            data.price_str = formatCurrency(data.price, 'cl', '$', 'CLP')
            data.price_discount_str = formatCurrency(data.price_discount, "cl", '$', 'CLP')
            data.order = data.order || 10000;

            data.uid = doc.id;
            data.link = "/product/" + doc.id;
            return data;

          }).filter(function(product: any) {
            return product.enabled === true && product.active === true && product.availableEcommerce === true;
         });

        }else {
            console.log("null");
        }


        this.products.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));


        return this.products;

    }

    async removeAllFromCart (uid: String, count: number, price: number ) {
      var cart = await this._currentCart?.getValue();
      var cartProducts = cart?.products ?? [];
      var cartAmount = (cart?.amount || 0);
      cartAmount -= (price * count);
      if (cartAmount < 0) {
        cartAmount = 0;
      }

      console.log("uid", uid);

      console.log("cartProducts", cartProducts);

      cartProducts = cartProducts.filter((item: any) => item != uid);
      console.log("cartProducts", cartProducts);

      cart.products = cartProducts;
      cart.amount = cartAmount;

      this.firestoreService.updateOrder(cart.uid, cart);

      this._currentCart.next(cart);

      console.log("cart", cart);
    }

    async removeFromCart (uuid: String, count: number, price: number) {

        var cart = await this._currentCart?.getValue()

        var cartProducts = cart?.products ?? []

        var cartAmount = (cart?.amount || 0)
        cartAmount -= (price * count)


        const index: number = cartProducts.indexOf(uuid);
        if (index !== -1) {
            cartProducts.splice(index, 1);
        }

        if (cartAmount < 0) {
            cartAmount = 0
          }
        cart.products = cartProducts
        cart.amount = cartAmount;

        this.firestoreService.updateOrder(cart.uid, cart)

        this._currentCart.next(cart)

        console.log("cart", cart);

    }

    async addToCart (uuid: String, count: number, price: number, modificatorsData: any) {

        var cart = await this._currentCart?.getValue();

        var cartAmount = (cart?.amount || 0) * count;
        cartAmount += (price * count);

        var cartProducts = cart?.products ?? [];

        const productsToAdd = Array(count).fill(uuid);

        cartProducts = cartProducts.concat(productsToAdd);

        var modificators = (cart.modificators || []);


        let merged = (cart.modificators || {});

        for (const [key, value] of Object.entries(modificatorsData)) {
          console.log(key);
          console.log(value);

          const keyStr = key as string;

          merged[key] = value;


        }

        cart.products = cartProducts;
        cart.amount = cartAmount;
        cart.modificators = merged;
        console.log("cart", cart);

        this.firestoreService.updateOrder(cart.uid, cart);

        this._currentCart.next(cart);

    }

    async removePromotionFromCart (promotion: any) {

        var cart = await this._currentCart?.getValue()
        //console.log("promotion", promotion);

        var cartProducts = cart?.products ?? []
        var cartAmount = cart?.amount || 0
        var cartPromotions = cart?.promotions ?? []
        var cartDiscount = cart?.discount || 0

        const index: number = cartPromotions.indexOf(promotion);
        if (index !== -1) {
            cartPromotions.splice(index, 1);
        }

        //console.log("promotion.amount", (promotion.amount || 0));


        cartAmount -= (promotion.amount || 0)

        const productA = promotion.productsA[0] || ""
        const productB = promotion.productB || ""
        const amountA = promotion.amountA || 1

        for (let i = 0; i < amountA; i++) {

            const indexProductA: number = cartProducts.indexOf(productA);
            if (indexProductA !== -1) {
                cartProducts.splice(indexProductA, 1);
            }

        }

        const indexProductB: number = cartProducts.indexOf(productB);
        if (indexProductB !== -1) {
            cartProducts.splice(indexProductB, 1);
        }

        cartDiscount -= (promotion.discount || 0)

        if (cartAmount < 0) {
            cartAmount = 0
          }
        //console.log("cartAmount", cartAmount);

        cart.discount = cartDiscount;
        cart.products = cartProducts
        cart.amount = cartAmount;
        cart.promotions = cartPromotions

        //console.log("cart", cart);

        this.firestoreService.updateOrder(cart.uid, cart)

        this._currentCart.next(cart)

    }

    async addPromotionToCart (promotion: any) {

        var cart = await this._currentCart?.getValue()

        var cartProducts = cart?.products ?? []

        cartProducts = cartProducts.concat(promotion.productsA || [])
        cartProducts.push(promotion.productB)

        var cartPromotions = cart?.promotions ?? []

        var cartAmount = cart?.amount || 0

        console.log("cartAmount", cartAmount);

        cartAmount += promotion.amount || 0

        cartPromotions.push(promotion)

        var cartDiscount = cart?.discount || 0
        cartDiscount += promotion.discount || 0

        cart.discount = cartDiscount;
        cart.products = cartProducts
        cart.amount = cartAmount;
        cart.promotions = cartPromotions
        cart.updated = Date.now()

        console.log("promotion", cart);

        this.firestoreService.updateOrder(cart.uid, cart)

        this._currentCart.next(cart)

    }

    async addDiscountToCart (discount: number) {

        var cart = await this._currentCart?.getValue()


        var cartDiscount = cart?.discount || 0
        cartDiscount += discount

        cart.discount = cartDiscount;

        this.firestoreService.updateOrder(cart.uid, cart)

        this._currentCart.next(cart)

    }

    private async commonHeaders (defaultToken: boolean): Promise<HttpHeaders> {

        var headers = new HttpHeaders();
        headers = headers.append('Content-Type', "application/json");
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Access-Control-Allow-Credentials', 'true');
        headers = headers.append('Access-Control-Allow-Origin', "*");
        headers = headers.append("Access-Control-Allow-Headers", "X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method,Access-Control-Request-Headers, Authorization");
        headers = headers.append("Access-Control-Allow-Methods", "HEAD, GET, POST, PUT, PATCH, DELETE, OPTIONS");
        if (defaultToken) {
            headers = headers.append('Authorization', 'Bearer '+ DEFAULT_TOKEN);
        }else {

            const token = await this.currentFBUser?.getIdToken(true)
            //console.log('token', token);

            headers = headers.append('Authorization', 'Bearer '+ (token || ''));

        }

        return headers
    }

    async userData () {


        const data = await this._documentCustomer?.ref.get().then((doc) => {

            if (doc.exists) {

                let customerData = doc.data();

                console.log("userData", customerData);

                let uid = customerData.uid || "";
                let displayName = customerData.displayName || "";
                let email = customerData.email || "";
                let phone = customerData.phone || "";
                let img = customerData.photoURL || "";

                const data = (null != phone) ? {
                        uid: uid,
                    displayName: displayName,
                    email: email,
                    phone: phone,
                    img: img,
                    updated: Date.now()
                } : {
                    uid: uid,
                    displayName: displayName,
                    email: email,
                    img: img,
                    updated: Date.now()
                };
                return data;

            }
            return null;

        });

        return data
/*
        await this._documentCustomer.ref.get().then{

        }

        this._documentCustomer?.ref.

        let uid = this._currentUser.getValue?.uid || "";
        let displayName = this.currentFBUser?.displayName || "";
        let email = this.currentFBUser?.email || "";
        let phone = this.currentFBUser?.phoneNumber || "";
        let img = this.currentFBUser?.photoURL || "";

        const data = (null != phone) ? {
              uid: uid,
            displayName: displayName,
            email: email,
            phone: phone,
            img: img,
            updated: Date.now()
          } : {
            uid: uid,
          displayName: displayName,
          email: email,
          img: img,
          updated: Date.now()
        };
        return data;
        */
    }

/*

    get currentUserLastValue() {
        return this._currentUser.getValue();
    }

    get currentCartLastValue() {
        return this._currentCart.getValue();
    }
     */
    ngOnDestroy() {
 //       console.log('ngOnDestroy: cleaning up...');
        this.cartSubscription?.unsubscribe()
        this._currentCart?.unsubscribe()
        clearInterval(this.timer);
      }
}
