import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { CartComponent } from './cart/cart.component';
import { HomeComponent } from './home/home.component';
import { StoresComponent } from './stores/stores.component';
import { ProductsComponent } from './products/products.component';
import { CategoriesComponent } from './categories/categories.component';
import { HistoryComponent } from './history/history.component';
import { AddressesComponent } from './addresses/addresses.component';
import { PromotionsComponent } from './promotions/promotions.component';

const routes: Routes = [
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'stores',
    component: StoresComponent
  },
  {
    path: 'promotion',
    component: PromotionsComponent
  },
  {
    path: 'promotion/:uuid',
    component: PromotionsComponent
  },
  {
    path: 'product',
    component: ProductsComponent
  },
  {
    path: 'product/:uuid',
    component: ProductsComponent
  },
  {
    path: 'category',
    component: CategoriesComponent
  },
  {
    path: 'addresses',
    component: AddressesComponent
  },
  {
    path: 'history',
    component: HistoryComponent
  },
  {
    path: 'category/:uuid',
    component: CategoriesComponent
  }, {
    path: '',
    component: HomeComponent,
    children: [
        {
            path: 'stores',
            component: StoresComponent
        },
        {
          path: 'cart',
          component: CartComponent
      }
    ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
