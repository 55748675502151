// auth.service.ts
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FirestoreService } from './firestore.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
     currentUser: firebase.User | null = null;
    private authState: Observable<firebase.User | null>;

  isLoggued : Boolean = false;

  constructor(private firestoreService: FirestoreService, private dataService: DataService, private firebaseAuth: AngularFireAuth,
    private router: Router) {

    this.authState = firebaseAuth.authState;

    console.log('AuthService!');


    this.authState.subscribe(async user => {
        if (user) {
          this.currentUser = user;
          //this.localStorage.storeSimple('userData', user)
          //this.openSnackBar('Successfully authenticated');
          console.log('AUTHSTATE USER', user);

          const idToken = await firebase.auth().currentUser?.getIdToken();

          //console.log('Token', idToken)

          if (this.isLoggued) {
        //    this.router.navigate(['dashboard']);

          }else {


          let uid = user.uid
          let displayName = user.displayName
          let email = user.email
          let phone = user.phoneNumber
          let img = user.photoURL

          const data = (null != phone) ? {
                uid: uid,
              displayName: displayName,
              email: email,
              phone: phone,
              img: img,
              auth_token: idToken,
              updated: Date.now()
            } : {
              uid: uid,
            displayName: displayName,
            email: email,
            img: img,
            auth_token: idToken,
            updated: Date.now()
          };

            //this.isLoggued = true;

            this.dataService.updateCurrentUser(data, firebase.auth().currentUser )


          this.firestoreService.updateCustomer(uid, data).then(response => {

     //       this.router.navigate(['dashboard']);
            this.isLoggued = true;


            //this.router.navigate(['/']);

            }).catch(error => {
                console.log('AUTHSTATE USER EMPTY', error);

            });


          }

        } else {

          console.log('AUTHSTATE USER EMPTY', user);
          this.currentUser = null;
          this.isLoggued = false;

        }
      },
        err => {
          console.log('AUTHSTATE USER error', err);

          //this.openSnackBar(`${err.status} ${err.statusText} (${err.error.message})`, 'Please try again')
        });

 }


 async userToken () {
   return await firebase.auth().currentUser?.getIdToken(true)
 }

  isLoggedIn(): Boolean {

    console.log('user!', this.firebaseAuth.currentUser);

    //this.firebaseAuth.currentUser

    console.log('isLoggedIn!', this.isLoggued);

    return this.isLoggued;//this.authState !== null;

    //return !!this.firebaseAuth.currentUser;

  }

  signup(email: string, password: string) {

    this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Success!', value);
      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
      });

  }

  login(email: string, password: string) {
    return new Promise((resolove, reject) => {
        this.firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .then(userData => {
            this.isLoggued = true;
            resolove(userData)

            let uid = userData.user?.uid ?? ""
            let displayName = userData.user?.displayName
            let email = userData.user?.email
            let phone = userData.user?.phoneNumber
            let img = userData.user?.photoURL

            const data = {
                displayName: displayName,
                email: email,
                phone: phone,
                img: img,
                created: Date.now()
              };



            this.firestoreService.updateCustomer(uid, data).then(response => {
                resolove(userData)


              }).catch(error => {
                reject(error)

              })

            }
            ).catch(err => {
                 reject(err)

         });
      });
  }

  loginWithGoogle() {

    var provider = new firebase.auth.GoogleAuthProvider();

    return new Promise((resolove, reject) => {
    this.firebaseAuth.signInWithPopup(provider).then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential?.providerId;

        console.log('signInWithPopup! result', result);
        // The signed-in user info.
        var user = result.user;

        console.log('signInWithPopup! user', user);

        resolove(user)
        // ...
      }).catch(function(error) {
        // Handle Errors here.
        console.log('error! error', error);

        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
      });
  }

  logout() {
    return new Promise((resolove, reject) => {
        this.firebaseAuth.signOut().then(() => {
            this.isLoggued = false;
                      this.currentUser = null;
                      this.dataService.logoutUser()
                      this.router.navigate(['/']);

            resolove(true), (err: any) => reject(err)
        });
    });
  }

}
