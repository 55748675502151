import { ModuleWithProviders } from "@angular/core";
import { NgModule } from "@angular/core";
import { DataService } from "./services/data.service";

@NgModule()
export class SharedModule { 
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
        ngModule: SharedModule,
        };
    }
}