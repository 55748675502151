// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBJZO3x6h1HrpAt8F7_-QokjD-5GVs9c_I",
    authDomain: "pascucci-f33c4.firebaseapp.com",
    projectId: "pascucci-f33c4",
    storageBucket: "pascucci-f33c4.appspot.com",
    messagingSenderId: "715428411457",
    appId: "1:715428411457:web:411eafabb1a05bb950aec0",
    measurementId: "G-BCZZ6BZS8E"
  }
};

export class Constants {


}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
