import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { BaseComponent } from "../base.component";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { FirestoreService } from "../services/firestore.service";
import { MessagingService } from "../services/messaging.service";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { formatCurrency } from "@angular/common";
import { observable, Observable } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html'
  })
  export class HistoryComponent extends BaseComponent {

    public orders: any = {};
    public followingOrders: Array<any> = [];
    public pastOrders: Array<any> = [];
    public subscriptions: Array<any> = [];
    public urls: any = []

    constructor(
      public sanitizer: DomSanitizer,
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef, 
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService,
      private route: ActivatedRoute) {
              
        super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);

      }

    async loadData () {

      super.loadData()

      console.log("history loadData");


      this.orders = (await this.dataService.ordersHistory(this.user.uid || ""))
      
      this.followingOrders = this.orders.ordersPending.reverse() || [];
      this.pastOrders = this.orders.ordersHistoric.reverse() || [];

      this.subscriptions.forEach (subscription => {

        subscription.unsubscribe()
     
      });

      this.subscriptions = []

      this.followingOrders.forEach(element => {

        const url = this.urlForOrderAddress(element)

        this.urls.push(url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : null);

        const subscription = this.firestoreService.subscribToOrder(element.uid).subscribe(order => {
          console.log('subscribToOrder ', order.payload.data())
          this.changeActiveOrder(order.payload.id, order.payload.data())

        })


        this.subscriptions.push(subscription);

     
      });

      /*
      console.log("pastOrders ", this.pastOrders);
      this.orders.forEach(element => {

        console.log("followingOrders ", this.followingOrders);

        if (element.status === 2) {
          this.followingOrders.push(element)
        }else {
          this.pastOrders.push(element)
        }
      });
*/
      console.log("followingOrders ", this.followingOrders);
      console.log("pastOrders ", this.pastOrders);

    }

    changeActiveOrder(id: String, newValue: any) {
      
      var order = this.followingOrders.find(element => {
        return element.uid === id;
      });

      let index = this.followingOrders.indexOf(order);

      if (-1 != index) {
        this.followingOrders[index] = newValue
      }

    }

    totalForOrder (order: any): string {

      const amount = (order.paymentData?.amount) || (order.amount.toString() || "0")

      return formatCurrency((amount || 0), "cl", '$', 'CLP')

    }

    textForWorker(data: any): string {

      var returnValue = "--"
  
      if (data.workers && data.workers.length > 0) {
        
        const worker = data.workers[data.workers.length-1]
    
        returnValue = (worker.name && worker.status === 8) ? worker.name : returnValue

      }
  
      return returnValue
  
    }

    textForStatus(status: number): string {

      switch (status) {
        case 2: 
        return "Pagado";
        case 1: 
        return "Pendiente";
        case 3: 
        return "Rechazado";
        case 4: 
        return "Completado";
        case 5: 
        return "Sin stock";
        case 6: 
        return "Pendiente de pago";
        case 7: 
        return "En preparación";
        case 8: 
        return "En reparto";
        case 9: 
        return "Pendiente confirmar pago";
        default:
          return "";
      }
  
  
    }

    urlForOrderAddress (order: any): (string | null) {

      var urlAddress = null

      const address = order.delivery.address

      if (address) {

        const addressStr = address.lat + "," + address.lng

        var base64 = btoa(addressStr); // lazy "long,lat" formatting
       // console.log("base64 ", base64);
        
        urlAddress = "https://www.google.com/maps/embed?pb=!1m7!1m2!1m1!1d2256.8774176856136!3m3!1m2!1s0!2z" + base64

      }

      return urlAddress

    }

    urlAddress (order: any): any {

      return this.urls[order.uid]

    }

    ngOnDestroy (){

      this.subscriptions.forEach (subscription => {

        subscription.unsubscribe()
     
      });


    }


    dateForOrder (order: any): string {

      return formatCurrency((order.amount || 0), "cl", '$', 'CLP')

    }

    async cartReady() {

      super.cartReady()

    }

  }

  
