import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { BaseComponent } from "../base.component";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { FirestoreService } from "../services/firestore.service";
import { MessagingService } from "../services/messaging.service";

@Component({
    selector: 'app-products',
    templateUrl: './promotions.component.html'
  })
  
  export class PromotionsComponent extends BaseComponent {

    public productsA: Array<any> = [];
    public productsB: Array<any> = [];

    public selectedProductA: any = null;
    public selectedProductB: any = null;

    public promotion: any = null;

    public count = 0
    public countInCart = 0

    constructor(
      public notifierService: NotifierService,
      public messagingService: MessagingService,
      public authService: AuthService,
      public cd : ChangeDetectorRef,
      public element: ElementRef, 
      public http: HttpClient,
      public firestoreService: FirestoreService,
      public dataService: DataService,
      private route: ActivatedRoute) {

      super(notifierService, messagingService, authService, cd, element, http, firestoreService, dataService);
        
      console.log(this.route);

      this.route.params.subscribe(async params => {

          this.uuid = params['uuid'];

          console.log(this.uuid);

          const currentPromotion = await this.dataService.promotionByUID(this.uuid);
        
          this.productsA = await this.dataService.productsByCategory(currentPromotion.products_a);
          this.productsB = await this.dataService.productsByCategory(currentPromotion.products_b);

          this.promotion = currentPromotion

      });
      
    }

    async loadData () {

      super.loadData()
/*
      if (this.product) {

        this.countInCart = (this.cart.products || []).reduce((pre: number, cur: string) => (cur === this.product.uid) ? ++pre : pre, 0)

      }

      */
    }

    public async addToCart() {

      if (!this.authService.isLoggedIn()) {
        this.loginWithGoogle()

      }else {

        var totalAmount = 0

        const amountA = this.promotion.amount_a || 0


        const priceA = amountA * this.priceNumberForProduct(this.selectedProductA)

        totalAmount += priceA

        /*
        for (const productA of this.productsA) {

          const price = this.priceNumberForProduct(productA)
          totalAmount += price
          //await this.dataService.addToCart(productA.uid, 1, productA.price_discount)
        }
*/
        const priceB = this.priceNumberForProduct(this.selectedProductB)

        totalAmount += priceB

        const discount = this.promotion.price - totalAmount

        var productsAArray = [];
        for(let i = 0; i < amountA; i++){ 

          productsAArray.push(this.selectedProductA.uid)
        }

        var promotion = {uid: this.promotion.uid,
          amountA: amountA,
                        productsA: productsAArray,
                        productB: this.selectedProductB.uid,
                        price: this.promotion.price,
                        amount: totalAmount,
                        name: this.promotion.name,
                        discount: discount
                      }

        await this.dataService.addPromotionToCart(promotion)

        //await this.dataService.addDiscountToCart(discount)

      }



    }

    selectProductA (product: any) {
      
      this.selectedProductA = product

    }

    selectProductB (product: any) {
      
      this.selectedProductB = product

    }

    productAName () {
      return this.selectedProductA ? this.selectedProductA.name : this.productsA[0].name
    }

    productBName () {
      return this.selectedProductB ? this.selectedProductB.name : "Elige tu agregado"
    }



  }
