
<router-outlet></router-outlet>

<html lang="es">

<head>


  <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>

  <meta name="robots" content="index, follow" />
  <meta name="keywords" content="" />
  <meta name="description" content="" />

  <title></title>

  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <meta http-equiv="expires" content="wed, 30 jul 2014 14:30:00 GMT">

  <meta property="og:site_name" content=""/>
  <meta property="og:title" content=""/>
  <meta property="og:description" content=""/>
  <meta property="og:url" content="" />
  <meta property="og:image" content=""/> 
  
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap" rel="stylesheet">


 
  <!--[if lt IE 9]>
  	<script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
  	<script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
  <![endif]-->

</head>
<body>

  <header class="bg-header wow animate fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
    <h1>
      Logo Ricón Árabe
      <a class="bg-header" [routerLink]="['/']">
        <img src="/assets/img/logo.png" alt="">
      </a>
    </h1>

    <ng-container *ngIf="'Guest' != user.displayName; else guess_user">
      <nav class="bg-header">
        <ul>
          <li><a [routerLink]="['/history']">Mis pedidos</a></li>
          <li><a href="mailto:contacto@riconarabe.cl">Contacto</a></li>
          <li><a [routerLink]="['/stores']">Tiendas</a></li>
          <li><a [routerLink]="['/addresses']">Direcciones</a></li>
          <li><a (click)="logout()">Cerrar sesión</a></li>
        </ul>
      </nav>
    </ng-container>
    <ng-template #guess_user>
      <nav class="bg-header">
        <ul>
          <li><a href="mailto:contacto@riconarabe.cl">Contacto</a></li>
          <li><a [routerLink]="['/stores']">Tiendas</a></li>
        </ul>
      </nav>
    </ng-template>

    <a [routerLink]="['/cart']" class="car">
      <img src="/assets/img/car.png" alt="">
      <span>{{cartNumberProducts}}</span>
    </a>

    <a class="login" (click)="loginWithGoogle()">
      <img src="/assets/img/login.png" alt="">
    </a>

    <a class="login-perfil" (click)="loginWithGoogle()">
      <p>{{(textForCustomer(user))}}</p>
      <img src={{imageForCustomer(user)}} alt="">
    </a>

    <button class="burger">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </header>


<main>

  <section class="main-banner dark wow animate fadeIn" data-wow-duration="1s" data-wow-delay="1s">
<!-- 
    <owl-carousel-o [options]="{loop:true,
      rewind:true,
        rewindNav:false,
        lazyLoad:true,
        dots: false,
        autoplay: true,
        items: 1,
        autoplayTimeout: 6000,
        autoplayHoverPause: false,
        autoplaySpeed: 3000,
        dotsContainer: '#carousel-custom-dots'
      }">

      <ng-container *ngFor="let promotion of promotions">
        <ng-template carouselSlide [id]="promotion.id">

        <div class="item">
          <h2 class="banner-tittle">{{promotion.name}}</h2>
          <figure class="imgLiquid"><img src={{promotion.imgs[0]}} alt=""></figure>
        </div>

        </ng-template>
      </ng-container>


    </owl-carousel-o>  -->


  </section>

  <section class="shops wow animate fadeIn bg-blanco" data-wow-duration="1s" data-wow-delay="1.5s">
  	<div class="container">

      <div class="row shopList">
        <div class="col-12">
          <p><span>{{store.name}}</span>
            {{store.description}}</p>
        </div>
      </div>
    </div>
  </section>

</main>

<footer>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<a href="javascript:void(0)" class="top bg-cafe"><img src="/assets/img//arrow-top.png" alt=""></a>
				<ul>
          <li><a href={{facebookUrl()}} target="_blank" rel="noopener noreferrer"><img src="/assets/img/ico-fb.png" alt=""></a></li>
          <!-- <li><a href="#"><img src="/assets/img/ico-tw.png" alt=""></a></li>
          <li><a href="#"><img src="/assets/img/ico-yt.png" alt=""></a></li> -->
          <li><a href={{instagramUrl()}} target="_blank" rel="noopener noreferrer"><img src="/assets/img/ico-insta.png" alt=""></a></li>

				</ul>
				<p>Dirección</p>
			</div>
		</div>
	</div>
	
</footer>


</body>
</html>